import React, { useEffect, useState } from "react";
import { Flex, Badge, Button, Col, Tag, Modal, Form, Input } from "antd";
import { useIntl } from "react-intl";
import "./GroupSection.scss";
import { LinkOutlined, DisconnectOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { UserBranch, UserShop } from "shared/dist";

interface DetailSectionProps {
  branch: UserBranch
  onIsActiveShopChange: (values: Partial<UserBranch>) => Promise<void>
  onDeleteShop: (values: Partial<UserBranch>) => Promise<void>
  onSubmit: (values: Partial<UserShop>) => Promise<void>
}

export const GroupSection: React.FC<DetailSectionProps> = ({ branch, onDeleteShop, onIsActiveShopChange, onSubmit }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState(0)
  const showModal = async (titleMessage: string, sendDataMode: number) => {
    setMode(sendDataMode);
    setTitle(titleMessage);
    setIsModalOpen(true);
  };

  const handelOk = async (data: Partial<UserBranch>) => {
    await onDeleteShop(data);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: Partial<UserShop>) => {
    console.log("Received values of form: ", values);
    onSubmit(values);
  };

  useEffect(() => {
    form.setFieldValue('branchName', branch.branchName);
  }, [branch.branchName])


  return (
    <div className="GroupSection" >
      <div className="custom-header-style">
          <Form
          form={form}
          name="basic"
          // labelCol={{ span: 9 }}
          // wrapperCol={{ span: 100 }}
          initialValues={{ branchName: branch.branchName }}
          onFinish={onFinish}
          autoComplete="off"
          // layout="vertical"
        >
          <div className="content-group-section">
            <div className="header-title">
              <h2>รายละเอียด</h2>
            </div>
            <Form.Item
            label={intl.formatMessage({ id: `branchPage.branchName` })}
            name="branchName"
            rules={[{ required: true, message: intl.formatMessage({ id: `message.pleaseInputFiled` }, { filed: intl.formatMessage({ id: `branchPage.branchName` }) }) }]}
          >
            <Input size="large" placeholder={intl.formatMessage({ id: `branchPage.branchName` })} disabled={branch.isMasterBranch} />
          </Form.Item>
            <div className="detail-group-section">
              <div className="open-txt">
                <div className="label-txt">
                  {intl.formatMessage({ id: `shopPage.service` })} :
                </div>
                <div>
                  {branch.isActive ? 
                  <Badge status="success" text={intl.formatMessage({ id: `shopPage.active` })} /> : 
                  <Badge status="error" text={intl.formatMessage({ id: `shopPage.inactive` })} /> }
                </div>
              </div>
              <div className="status-txt">
                <div className="label-txt">
                  {intl.formatMessage({ id: `shopPage.connectingStatus` })} : 
                </div>
                <div>
                  {branch.lineGroupIsConnected ? <Tag color="cyan" icon={<LinkOutlined />}>{intl.formatMessage({ id: `shopPage.connect` })}</Tag> : <Tag icon={<DisconnectOutlined />} color="volcano">{intl.formatMessage({ id: `shopPage.notConnect` })}</Tag>}
                </div>
              </div>
            </div>
            <Button type="dashed"
                className={ branch.isActive ? "button-line-black" : "button-line-black" }
                icon={branch.isActive ? <FeatherIcon icon="lock" /> : <FeatherIcon icon="unlock" />}
                // disabled={branch.isMasterBranch}
                onClick={() => showModal(intl.formatMessage({ id: branch.isActive ? `shopPage.temporarilyDisabled` : `shopPage.temporarilyEnabled` }), 1)}
              >
                {branch.isActive ? intl.formatMessage({ id: `shopPage.temporarilyDisabled` }) : intl.formatMessage({ id: `shopPage.temporarilyEnabled` })}
              </Button>
            </div>
          
        </Form>
        <div className="groupsection-button-control">
          <Button type="primary" htmlType="submit" className="button-main" size="large" disabled={branch.isMasterBranch} >
              {intl.formatMessage({ id: `shopPage.saveData` })}
          </Button>
          <div></div>
          <div></div>
          <Button size="large"  type="text" disabled={branch.isMasterBranch} danger className="button-text-danger"  onClick={() => showModal("ต้องการจะลบร้านค้าใช่ไหม", 2)} >
            <FeatherIcon icon="trash-2" />
            {intl.formatMessage({ id: `shopPage.deleteShop` })}
          </Button>
        </div>

      
      </div>
      <Modal title={title} open={isModalOpen} onOk={() => handelOk(mode === 1 ? { isActive: !branch.isActive } : { isDeleted: !branch.isDeleted })} onCancel={handleCancel}></Modal>
    </div>
  );
};
