import React, { useEffect, useState } from 'react';
import { Form, Input, Button, InputNumber, Divider, Switch, DatePicker, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { api } from 'api/instances'
import { PATHS } from 'api/paths';
type CreditPackageParams = {
  id?: string;
}

export const CreditPackageForm: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<CreditPackageParams>();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      fetchCreditPackage();
    }
  }, [id]);

  const fetchCreditPackage = async () => {
    setLoading(true);
    try {
      const response = await api.get(`http://localhost:3333/api/package/detail/${id}`);
      // console.log(response)
      const creditPackage = response.data;
      creditPackage.startAt = moment(creditPackage.startAt);
      creditPackage.endAt = moment(creditPackage.endAt);
      form.setFieldsValue(creditPackage);
      setImageUrl('http://localhost:3333'+creditPackage.imgUrl)
    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = (info: any) => {
    if(info.file.status === 'done'){
      setImageUrl(info.file.response.url)
      form.setFieldsValue({ imgUrl: info.file.response.url });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      if (id) {
        await api.post(`${PATHS.CREDIT_PACKAGE_UPDATE}/${id}`, values);
        message.success('CreditPackage updated successfully');
        navigate('/admin/package')
      } else {
        await api.post(PATHS.CREDIT_PACKAGE_ADD, values);
        message.success('CreditPackage added successfully');
        navigate('/admin/package')
      }
    } catch (error) {
      console.error('Failed to save creditPackage:', error);
      message.error('Failed to save creditPackage');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <h1>{id ? 'Update' : 'Add'} Credit Package</h1>
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="name" label="Package Name" rules={[{ required: true, message: 'Please enter the creditPackage name' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="creditAmount" label="จำนวนเครดิต" rules={[{ required: true, message: 'Please enter the credit amount' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="price" label="ราคา" rules={[{ required: true, message: 'Please enter the price' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="creditPerDay" label="เครดิตสูงสุดต่อวัน" rules={[{ required: true, message: 'Please enter the credit per day' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="expirationDays" label="จำนวนวันเครดิต" rules={[{ required: true, message: 'Please enter the expiration days' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="maxConnect" label="เชื่อมต่อสูงสุด" rules={[{ required: true, message: 'Please enter the max connect' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="description" label="รายละเอียด" rules={[{ required: true, message: 'Please enter the description' }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="imgUrl" label="Image URL" >
      {imageUrl &&  <><img src={imageUrl} alt="uploaded image" style={{ marginTop: 10, maxHeight: 200 }} /><Divider /></>}
     
        <Upload
          name="file"
          action="http://localhost:3333/api/package/upload" // Replace with your actual upload URL
          onChange={handleUploadChange}
          listType="picture"
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      
      </Form.Item>
      <Form.Item name="isActive" label="เปิดใช้งาน" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="startAt" label="วันที่เริ่มต้น" rules={[{ required: true, message: 'Please select the start date' }]}>
        <DatePicker showTime />
      </Form.Item>
      <Form.Item name="endAt" label="วันที่สิ้นสุด" rules={[{ required: true, message: 'Please select the end date' }]}>
        <DatePicker showTime />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {id ? 'Update' : 'Add'} CreditPackage
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};
