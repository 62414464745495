import { api } from "./base";
import { CreditPackage } from "interfaces/CreditPackage";

export const USER_PACKET_DETAIL_URL = "/user-package/detail/:id";
export const USER_PACKAGE_CREARTE_URL = '/user-package/create';

export async function getUserPackage(id: string) {
  const result = await api.get(USER_PACKET_DETAIL_URL.replace(`:id`, id))
  return result.data
}

export async function createUserPacket(shopName: string, creditPackage: CreditPackage) {
  const result = await api.post(USER_PACKAGE_CREARTE_URL, { shopName, creditPackage });
  return result.data
}