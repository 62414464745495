import { UserPackage } from "./UserPackage"

export enum FlexboxType {
  card = 'Card',
  text = 'Text'
}

export enum LINEGroupType {
  lineOA = 'OA',
  lineGroup = 'GROUP'
}

export enum ShopChangePackage {
  connect = 'CONNECT',
  waiting = 'WAIT',
  active = 'ACTIVE',
  inActive = 'INACTIVE',
}

export enum ShopStatus {
  active = 'active',
  inActive = 'inactive',
  cancel = 'cancel',
  ban = 'ban'
}

export enum CustomerStatus {
  active = 'active',
  inActive = 'inactive',
  ban = 'ban'
}

export interface UserShop {
  id?: number
  userId: number
  shopName: string
  shopStatus: ShopStatus
  shopIsActive: boolean
  userPackageId?: number
  isFreePackage: boolean
  isAutoRenewed: boolean 
  isAutoTopUp: boolean  
  flexboxType: FlexboxType
  userBranchs: UserBranch[]
  bankAccounts: BankAccount[]
  createdAt?: Date
  updatedAt?: Date
  isDeleted?: boolean
  userPackage?: UserPackage
}

export enum NotificationType {
  lineBroadcast = 'LineBroadcast',
  lineNoti = 'LineNoti',
  both = 'Both'
}

export enum PurchaseStatus {
  success = 'success',
  wait = 'wait',
  fail = 'fail'
}

export enum TransectionStatus {
  success = 'success',
  Processing = 'processing',
  fail = 'fail',
}

export interface UserBranch {
  id?: number
  userId: number
  shopId: number
  branchName: string
  isMasterBranch: boolean
  lineGroupType: LINEGroupType
  lineGroupQrCode: string
  lineGroupIsConnected: boolean
  lineGroupName: string
  lineGroupId: string

  lineChannelSecret: string
  lineChannelToken: string
  lineChannelTokenLongLive: string
  isEnablePayoutNotification: boolean
  webhookMessagingApi: string
  lineNotifyToken: string
  
  validSlip: NotificationType
  duplicateSlip: NotificationType
  invalidSlip: NotificationType
  invalidPayee: NotificationType
  invalidQrCode: NotificationType
  flexboxType: FlexboxType

  detectDuplicateSlip: boolean
  detectMinAmount: number
  hidePayerAccount: boolean
  hidePayeeAccount: boolean

  isActive: boolean
  isDeleted: boolean
  preventUpdateWebhook: boolean
  userShop: UserShop

  reference: string
  errorLog: string

  userBranchBankAcounts?: BankAccount[]

  totalRecord : number 
  totalAmount: number
}

export interface BankAccount {
  id?: number
  userBranchId?: number
  bankCode: string
  accountNumber: string
  firstNameTh: string
  lastNameTh: string
  firstNameEn: string
  lastNameEn: string
}

