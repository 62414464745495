import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { ShopOutlined } from '@ant-design/icons';

interface ModalProductInterface {
  modelTitle: string;
  isOpenModel: boolean;
  cancelModel: any;
  onOk: any;
  confirmLoading: boolean;
  placeholder: string;
}

export const ModalService: React.FC<ModalProductInterface> = ({ modelTitle, isOpenModel, cancelModel, onOk, confirmLoading, placeholder }) => {
  const [valiable, setValiable] = useState("");
  return (
    <Modal
      title={modelTitle}
      open={isOpenModel}
      onOk={() => onOk(valiable)}
      confirmLoading={confirmLoading}
      onCancel={cancelModel}
    >
      <Input placeholder={placeholder} prefix={<ShopOutlined />} value={valiable} onChange={(e) => setValiable(e.target.value)} />
    </Modal>
  );
}
