import ProfileFrom from "./ProfileFrom";
import React, { useEffect } from "react";
import { Header } from "../Header";
import { useIntl } from 'react-intl'
import { useUserAccount } from "api/useUserAccount";
import { Form } from "antd";

export const Profile: React.FC = () => {
  const intl = useIntl();
  const [formProfile] = Form.useForm();
  const { account } = useUserAccount()

  useEffect(() => {
    formProfile.setFieldsValue({ firstName: account.firstName, lastName: account.lastName, contact: account.contact, phone: account.phone });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
      <>
        <Header title={intl.formatMessage({ id: `common.profile` })} subtitle={intl.formatMessage({ id: `common.account` })} />
        <ProfileFrom form={formProfile} />      
      </>
  );
};