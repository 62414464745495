import React from "react";
import "./FilterForm.scss";
import { Form, Input, Button, Row, Col, DatePicker , ColProps } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import FeatherIcon from "feather-icons-react";

const { RangePicker } = DatePicker;;

const colMediumStyle: ColProps = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 12 },
  xxl: { span: 12 },
};

interface FilterFormProps {
  onSubmit(data: { username: string; password: string; remember: boolean }): void;
}

export const FilterForm: React.FC<FilterFormProps> = ({ onSubmit }) => {
  const intl = useIntl();
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    onSubmit(values);
  };

  return (
    <Form
      name="customer-tab-filter-form"
      className="filter-form"
      onFinish={onFinish}
    >
      <div className="header-title">
        <h2>ค้นหารายการสลิป</h2>
      </div>
      <Row gutter={[30, 0]}>
        <Col {...colMediumStyle} >
          <Form.Item
            name="nickname"
            label={<IntlMessages id="branchPage.nickname" />}
          >
            <Input size="large" placeholder={intl.formatMessage({ id: `branchPage.nickname` })} />
          </Form.Item>
        </Col>
          <Col {...colMediumStyle} >
          <Form.Item
            name="timeToSendSlip"
            label={<IntlMessages id="branchPage.timeToSendSlip" />}
          >
            <RangePicker size="large"  />
          </Form.Item>
        </Col>
        <Col xl={5} lg={5} md={8} sm={14} xs={24}>
          <Button size="large" type="primary" className="button-main" block htmlType="submit" icon={<FeatherIcon icon="search" />} >
            <IntlMessages id="common.search" />
          </Button>
        </Col>
        <Col xl={4} lg={4} md={7} sm={10} xs={24}>
          <Button size="large" type="default" className="button-main-line"  block htmlType="reset">
            <IntlMessages id="common.clear" />
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col span={4}>
          <Button size="large" type="primary" htmlType="submit" icon={<SearchOutlined />} >
            <IntlMessages id="common.search" />
          </Button>
        </Col>
        <Col span={3}>
          <Button size="large" type="default" htmlType="reset" icon={<ClearOutlined />} >
            <IntlMessages id="common.clear" />
          </Button>
        </Col>
      </Row> */}
    </Form>
  );
};
