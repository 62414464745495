import React from "react";
import { Card, Row, Col, Progress, ColProps } from "antd";
import { useIntl } from "react-intl";
import "./BranchOverviewTab.scss";
import { OverviewCard } from "components/OverviewCard";
import { BoldOutlined, UserSwitchOutlined, LineChartOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ChevronLeft } from "react-feather";
import { UserBranch } from "shared/dist";
import FeatherIcon from "feather-icons-react";

// const colSmallStyle: ColProps = {
//   xs: { span: 24 },
//   sm: { span: 24 },
//   md: { span: 8 },
//   lg: { span: 8 },
//   xxl: { span: 8 },
// };

const colMediumStyle: ColProps = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xxl: { span: 12 },
};

interface BranchOverviewTabProps {
  branch: UserBranch
}

export const BranchOverviewTab: React.FC<BranchOverviewTabProps> = ({ branch }) => {
  const intl = useIntl();
  const calPercentCredit = ((branch.userShop.userPackage?.creditAmount ?? 0) / (branch.userShop.userPackage?.creditPackage?.creditAmount ?? 1)) * 100

  return (
    <Card className="branch-overview-tab">
       <Row gutter={[16, 16]}>
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `branchPage.verifySlipAt` })}
            text="0"
            icon={<FeatherIcon icon="calendar" />}
            borderLeftColor='green' />
        </Col>
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.amountLowerSpecified` })}
            text="0"
            icon={<FeatherIcon icon="trending-down" />}
            borderLeftColor='yellow'
          />
        </Col>
        {/* <Col {...colSmallStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.fifteenMinutesLate` })}
            text="0"
            icon={<ClockCircleOutlined />}
            borderLeftColor='orange'
          />
        </Col> */}
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.totalSlips` })}
            text="0"
            icon={<FeatherIcon icon="layers" />}
            borderLeftColor='green'
          />
        </Col>
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.slipLowerLimitAmount` })}
            text="0"
            icon={<FeatherIcon icon="chevrons-down" />}
            borderLeftColor='yellow'
          />
        </Col>
        {/* <Col {...colSmallStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.slipFifteenMinutesLate` })}
            text="0"
            icon={<ClockCircleOutlined />}
            borderLeftColor='orange'
          />
        </Col> */}
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.totalIncorrectNameOrAccount` })}
            text="0"
            icon={<FeatherIcon icon="user-x" />}
            borderLeftColor='red'
          />
        </Col>
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.amountIncorrectNameOrAccount` })}
            text="0"
            icon={<FeatherIcon icon="file-minus" />}
            borderLeftColor='red'
          />
        </Col>
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.expiredOrNotFoundSlips` })}
            text="0"
            icon={<FeatherIcon icon="slash" />}
            borderLeftColor='red'
          />
        </Col>
        <Col {...colMediumStyle} >
          <OverviewCard
            title={intl.formatMessage({ id: `shopPage.usage` }, { usage: (branch.userShop.userPackage?.creditAmount ?? 0), limit: (branch.userShop.userPackage?.creditPackage?.creditAmount ?? 0) })}
            text={<Progress percent={calPercentCredit} showInfo={false} />}
            icon={<FeatherIcon icon="trending-up" />}
            borderLeftColor='red'
          />
        </Col>
      </Row>
    </Card>
  );
};
