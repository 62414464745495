import React, { useState } from "react";
import "./BankAccountForm.scss";
import { Button, Flex, Typography, Card, Modal } from "antd";
import { useIntl } from "react-intl";
import { SettingFilled, PlusOutlined } from "@ant-design/icons";
import { BankAccount } from "shared/dist";
import { BankAccountItemFrom } from "./BankAccountItemFrom";
import { BankAccountItemCard } from "./BankAccountItemCard";
import FeatherIcon from "feather-icons-react";

interface BankAccountFormProps {
  userBranchId?: number;
  onCreate(data: BankAccount): void;
  onEdit(id: number, data: BankAccount): void;
  onDelete(id: number): void;
  bankAcounts?: BankAccount[]
}

export const BankAccountForm: React.FC<BankAccountFormProps> = ({ userBranchId, onCreate, onEdit, onDelete, bankAcounts }) => {
  const intl = useIntl();
  const { Text } = Typography;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [mode, setMode] = useState('create');
  const initBankAccount: BankAccount = {
    id: 0,
    userBranchId: userBranchId,
    bankCode: "",
    accountNumber: "",
    firstNameTh: "",
    lastNameTh: "",
    firstNameEn: "",
    lastNameEn: "",
  }
  const [bankAccount, setBankAccount] = useState<BankAccount>(initBankAccount);

  const handleSubmit = async (data: BankAccount) => {
    try{
      setConfirmLoading(true)
      if(mode === 'create') 
        await onCreate({...data, userBranchId});
      else
        await onEdit(bankAccount.id as number, { ...data, userBranchId, id: bankAccount.id });
      setBankAccount(initBankAccount);
      setTimeout(() => {
        setConfirmLoading(false);
        setIsOpenModal(false);
      }, 1000);
    } catch (error){
      setConfirmLoading(false);
      console.error(error)
    }
  };

  const handleCancel = () => {
    setMode('create');
    setIsOpenModal(false);
    setConfirmLoading(false);
    setBankAccount(initBankAccount);
  };

  const handleEdit = (id: number) => {
    setMode('update');
    const bank = bankAcounts?.find(bank => bank.id===id)
    setBankAccount(bank ?? initBankAccount)
    setIsOpenModal(true);
  };

  const handleDelete = (id: number) => {
    const bank = bankAcounts?.find(bank => bank.id===id)
    setBankAccount(bank ?? initBankAccount)
    setIsOpenConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmLoading(true);
    await onDelete(bankAccount?.id as number);
    setTimeout(() => {
      setConfirmLoading(false);
      setIsOpenConfirmModal(false);
      setIsOpenModal(false);
    }, 1000);
  };

  const handleCreateBankAccountModel = async () => {
    setMode('create');
    setIsOpenModal(true);
    setConfirmLoading(false);
    setBankAccount(initBankAccount);
  };

  return (
    <div className="bankaccount-form" >
      <Card title={
        <Flex justify="space-between" align="center">
          <div>
          <FeatherIcon icon="user" /> {intl.formatMessage({ id: `settingPage.settingBankAccount` })}
          </div>
            
          <Button size="large" className="button-main" type="primary" onClick={handleCreateBankAccountModel} ><PlusOutlined />{intl.formatMessage({ id: `branchPage.addBankAccount` })}</Button>
        </Flex>}
      >

      {bankAcounts?.map((bank: BankAccount, index: number) => <BankAccountItemCard onEdit={handleEdit} onDelete={handleDelete} index={index + 1} bankAcount={bank} mode={mode} />)}
        <ul className="remark" >
          <li>**เพิ่มเติม โปรดใช้ ชื่อบัญชี จากธนาคาร เท่านั้น บางที ชื่อจากบัตรประชาชน ไม่ตรงกับธนาคาร โดยไม่ต้องมีคำนำหน้าชื่อ (นาง/นาย, MR/MS) **</li>
          <li>**เพิ่มเติม สำหรับใครบางคนที่ นามสกุลมี 2วรรค ให้ใช้ _ แทน วรรค เช่น ธันเดอร์ณ_อยุธยา **</li>
          <li>**วิธีการหาชื่อภาษาอังกฤษ ให้เปลี่ยนภาษาแอพธนาคารเป็นอังกฤษ แล้วโอนเงินเข้าบัญชี จะเจอชื่อบัญชีที่เป็นภาษาอังกฤษ **</li>
          <li>**หากเป็นชื่อบริษัทให้กรอกแค่ชื่อ เช่น บริษัท ธันเดอร์ โซลูชั่น จำกัด ให้กรอกชื่อ ธันเดอร์โซลูชั่น ส่วนนามสกุลใส่ _ ไว้ **</li>
          <li>**ส่วนชื่อภาษาอังกฤษก็กรอกแค่ชื่อ เช่น THUNDER SOLUTION CO., LTD. ก็กรอกแค่ THUNDERSOLUTION (ตัวเล็กตัวใหญ่ก็ได้) ส่วนนามสกุล _ ไว้</li>
          <li>**หากเป็นพร้อมเพย์ตัวเลือกธนาคาร ให้เลือกตรวจเฉพาะชื่อ **</li>
        </ul>

       
      </Card>
      <Modal
        title={<Text>{intl.formatMessage({ id: `branchPage.${mode}BankAccount` })}</Text>}
        open={isOpenModal}
        footer={false}
        onCancel={handleCancel}
      >
        <BankAccountItemFrom onSubmit={handleSubmit} onClose={handleCancel} loading={confirmLoading} onDelete={handleDelete} index={mode === 'create' ? (bankAcounts?.length ?? 0) + 1 : (bankAcounts?.map(i => `${i.id}`)?.indexOf(`${bankAccount.id}`) ?? 0) + 1} bankAcount={bankAccount} mode={mode} />
      </Modal>
      <Modal
        title={<Text>{intl.formatMessage({ id: `message.confirm` })}</Text>}
        open={isOpenConfirmModal}
        onOk={handleConfirmDelete}
        onCancel={() => setIsOpenConfirmModal(false)}
        confirmLoading={confirmLoading}
      >
        {intl.formatMessage({ id: `message.confirmDeleterEntity` },  { entity: intl.formatMessage({ id: 'bankAccountPage.entity'}) })}
      </Modal>
    </div>
  );
};
