import React, { useState } from "react";
import { Card, message } from "antd";
import "./CreateBranchCard.scss";
import IntlMessages from "components/IntlMessages";
import { ModalService } from "pages/components/ModalService";
import { useIntl } from "react-intl";
import FeatherIcon from "feather-icons-react";


interface CreateBranchCardProps {
  handelBranchCreate :  (shopName : string) => Promise<void>,
  size: number,
  limit: number,
}


export const CreateBranchCard: React.FC<CreateBranchCardProps> = ({
  handelBranchCreate,
  size,
  limit
}) => {

  const showModal = () => {
    setModalOpen(true);
  };

  const [openModal, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const intl = useIntl();
  const [messageApi, contextHolder] = message.useMessage();

  const handleOk = async ( branchName: string) => {
    setConfirmLoading(true);
    setTimeout(async () => {
      await handelBranchCreate(branchName);
      messageApi.open({       
        type: 'success',
        content: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `shopPage.entity` }) }),
        duration: 2,
      });

      setModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Card className="create-branch-card" onClick={showModal}>
        <div className="content">
          <FeatherIcon icon="plus" className="icon" /> 
            <h2 className="title title-highlight"><IntlMessages id="shopPage.createBranch" /></h2>
          <div className="label">({size}/{limit})</div>
        </div>
      </Card>
      <ModalService modelTitle={"โปรดตั้งชื่อ สาขา(ไว้จดจำ)"} isOpenModel={openModal} cancelModel={handleCancel} onOk={handleOk} confirmLoading={confirmLoading} placeholder="เช่น Market DD Shop" />
      {contextHolder}
    </>
  );
};
