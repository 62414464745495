import React, { useEffect, useState } from "react";
import { Button, Col, ColProps, Grid, Row, Tag, Typography , Badge , Divider } from "antd";
import { LinkOutlined, ControlTwoTone, DisconnectOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import FeatherIcon from "feather-icons-react";
import { LINEGroupType } from "shared/dist";
interface ShopData {
  branchList: any,
  shopId: number | undefined
}
const { Text } = Typography;

export const BranchList: React.FC<ShopData> = ({
  branchList = [],
  shopId = 0
}) => {
  const colShopHeader: ColProps = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 6 },
    lg: { span: 6 },
    xxl: { span: 6 },
  };
  const colShopHeader2nd: ColProps = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 6 },
    lg: { span: 6 },
    xxl: { span: 6 },
  };
  const intl = useIntl();
  const [branchDataList, setBranchDataList] = useState([])
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  useEffect(() => {
    setBranchDataList(branchList);
  }, [branchList]);
  return (
    <>
    {branchDataList?.map((branch : any ) => (
      <React.Fragment key={branch.id}>
    <Row justify="space-between" align="middle" className="card-branch">
      <Col {...colShopHeader}>
        <Text className="font-14" type="secondary">{branch.isMasterBranch ? 'สาขาหลัก' : 'สาขาย่อย'}</Text> <br />
        <span className="title-branch">{branch.branchName} </span>   <Tag className="font-12 tag-line">LINE {branch.lineGroupType} </Tag>    
      </Col>
      <Col {...colShopHeader2nd} style={{ alignItems: breakpoints.xs ? 'left': 'center', textAlign:  breakpoints.xs ? 'left': 'left', paddingTop:  breakpoints.xs ? '7px' : '0px'  }} >
        <Text className="font-14" type="secondary">{intl.formatMessage({ id: `status` })}</Text> <br />
        <span>{branch.isActive ? <Badge status="processing" text="ใช้งาน" /> : <Badge status="default" text="ปิดใช้งาน" />}</span>
      </Col>
      <Col {...colShopHeader2nd} style={{ alignItems:  breakpoints.xs ? 'left': 'center', textAlign:  breakpoints.xs ? 'left': 'left' , paddingTop:  breakpoints.xs ? '7px' : '0px' }} >
        <Text className="font-14" type="secondary">{intl.formatMessage({ id: `servicePage.connection` })}</Text> <br />
        { (branch.lineGroupType === LINEGroupType.lineGroup && branch.lineGroupId !== null) ||
        (branch.lineGroupType === LINEGroupType.lineOA && branch.lineChannelTokenLongLive !== null)   ?
                    <Tag icon={<LinkOutlined />} color="cyan">
                      {intl.formatMessage({ id: `servicePage.ready` })}
                    </Tag> :
                    <Tag icon={<DisconnectOutlined />} color="volcano">
                      {intl.formatMessage({ id: `servicePage.notReady` })}
                    </Tag>
                  }

      </Col>
      <Col {...colShopHeader2nd} style={{ alignItems: 'center', textAlign: breakpoints.xs ? 'left': 'center', paddingTop:  breakpoints.xs ? '7px' : '0px' }} >
        <Text className="font-14" type="secondary">ตั้งค่า</Text> <br />
        <Button danger type="link" onClick={() => navigate(`/shop/${shopId}/branch/${branch.id}?tab=settings`)}>
          {/* <SettingTwoTone twoToneColor="#900C3F" /> */}
          <FeatherIcon icon="settings" />
          {breakpoints.xs ?  <Text style={{color:"#a20005", fontSize:"0.8rem"}}>(กดเพื่อตั้งค่า)</Text>:'' }
        </Button>
      </Col>
    </Row>
    {/* <Divider /> */}
      </React.Fragment>
    ))}
  </>
  )
}
