import { Button, Card, Col, ColProps, Flex, Form, Grid, Input, Radio, Switch, Tag, Typography } from "antd";
import React, {memo} from "react";
import { ClockCircleOutlined, QqOutlined, CheckOutlined, SettingFilled, CopyOutlined } from "@ant-design/icons";
import { UserShop } from "shared/dist";
import { useIntl } from "react-intl";
interface HomepageProps {
  shopSetting: UserShop,
  setShopSetting: (data: UserShop) => void,
 
}

const Homepage: React.FC<HomepageProps> = (props: HomepageProps) => {
  const { shopSetting } = props
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const fontSize = breakpoints.xs ? "12px" : "15px";
  const marginBottonXS = breakpoints.xs ? "1rem" : "";
  const marginRight = breakpoints.xs ? "" : "1rem";

  const colStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xxl: { span: 12 },
  };
  const { Text } = Typography;
  const intl = useIntl();
  const onFinish = (values: Partial<UserShop>) => {
    console.log("Received values of form: ", values);
    //onSubmit(values);
  };


  return (
    <>
      <Col {...colStyle} style={{ marginBottom: "1rem" }}>
        <Flex>
          <Card
            style={{ width: "100%" }}
            className="custom-header-style"
            title={
              <>
                <Flex justify="space-between" align="center">
                  <p style={{ fontSize: fontSize }}>{intl.formatMessage({ id: `settingPage.detail` })}</p>
                  <Button
                    icon={<ClockCircleOutlined />}
                    style={{
                      background: "none",
                      border: "none",
                      color: "white",
                    }}
                  />
                </Flex>
              </>
            }
          >
            <Col span={24}>
              <Flex justify="space-between" align="center">
                <p>
                  <QqOutlined /> {intl.formatMessage({ id: `settingPage.shopName` })}
                </p>
                <p>{shopSetting.shopName}</p>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>
                  <QqOutlined /> {intl.formatMessage({ id: `settingPage.product` })}
                </p>
                <p>Verify Slip Tester</p>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>
                  <QqOutlined /> {intl.formatMessage({ id: `settingPage.status` })}
                </p>
                <Tag color="green">
                  <CheckOutlined /> {intl.formatMessage({ id: `settingPage.normalStatus` })}
                </Tag>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>
                  <QqOutlined /> {intl.formatMessage({ id: `settingPage.service` })}
                </p>
                <Tag color="green">{intl.formatMessage({ id: `settingPage.active` })}</Tag>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>
                  <QqOutlined /> {intl.formatMessage({ id: `settingPage.connectingStatus` })}
                </p>
                <Tag color="red">{intl.formatMessage({ id: `settingPage.notConnect` })}</Tag>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>
                  <QqOutlined /> {intl.formatMessage({ id: `settingPage.expiration` })}
                </p>
                <p>21 ก.พ. 2024 11:08</p>
              </Flex>
              <Col span={24} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Flex justify="space-between">
                  <Button type="primary" block style={{ marginRight: "1rem" }}>
                    Primary
                  </Button>
                  <Button type="primary" block>
                    Primary
                  </Button>
                </Flex>
              </Col>
              <Col span={24} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Flex justify="space-between">
                  <Button type="primary" block style={{ marginRight: "1rem" }}>
                    Primary
                  </Button>
                  <Button type="primary" block>
                    Primary
                  </Button>
                </Flex>
              </Col>
            </Col>
          </Card>
        </Flex>
      </Col>
      <Col {...colStyle}>
        <Flex justify="center" align="center">
          <Card
            style={{ width: "100%" }}
            className="custom-header-style"
            title={
              <>
                <Flex justify="space-between" align="center">
                  <p style={{ fontSize: fontSize }}>{intl.formatMessage({ id: `settingPage.generalSettings` })}</p>
                  <Button
                    icon={<SettingFilled />}
                    style={{
                      background: "none",
                      border: "none",
                      color: "white",
                    }}
                  />
                </Flex>
              </>
            }
          >
            <Form
              style={{ marginLeft: "1.2rem", marginRight: "1.2rem", marginTop: "1rem", marginBottom: "0.5rem" }}
              name="basic"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 100 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              //  onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Flex justify="space-between" align="center">
                <p>{intl.formatMessage({ id: `settingPage.webHook` })}</p>
                <SettingFilled />
              </Flex>

              <Input
                disabled
                variant="filled"
                size="large"
                suffix={
                  <Button>
                    <CopyOutlined />
                  </Button>
                }
                defaultValue={"https://www.webhook.com?API=test"}
              />

              <Form.Item
                style={{ marginTop: "0.9rem" }}
                label={intl.formatMessage({ id: `settingPage.shopName` })}
                name="contract"
                rules={[{ required: true, message: "Please input your contract" }]}
              >
                <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.shopName` })} value={shopSetting.shopName} />
              </Form.Item>

              <Flex justify="center">
                <Typography.Title level={5}>{intl.formatMessage({ id: `settingPage.automaticRenewal` })} </Typography.Title>
              </Flex>
              <Flex justify="space-between" align="center" style={{ paddingBottom: "1rem" }}>
                <p style={{ margin: "0" }}>
                  <CopyOutlined /> {intl.formatMessage({ id: `settingPage.expirationDate` })}
                </p>
                <Switch checked={shopSetting.isAutoRenewed} />
              </Flex>
              <Flex justify="space-between" align="center" style={{ paddingBottom: "1rem" }}>
                <p style={{ margin: "0" }}>
                  <CopyOutlined /> {intl.formatMessage({ id: `settingPage.beyondUse` })}
                </p>
                <Switch checked={shopSetting.isAutoTopUp} />
              </Flex>

              <Form.Item wrapperCol={{ offset: 0, span: 100 }}>
                <Button type="primary" htmlType="submit" block style={{ height: "45px" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Flex>
      </Col>

      <Col span={24}>
        <Flex>
          <Card
            style={{ width: "100%" }}
            className="custom-header-style"
            title={
              <>
                <Flex justify="space-between" align="center">
                  <p style={{ fontSize: fontSize }}>{intl.formatMessage({ id: `settingPage.settings` })}</p>
                  <Button
                    icon={<SettingFilled />}
                    style={{
                      background: "none",
                      border: "none",
                      color: "white",
                    }}
                  />
                </Flex>
              </>
            }
          >
            <Flex
              justify="center"
              align="center"
              style={{ backgroundColor: "blueviolet", marginTop: "1rem", marginLeft: "1rem", marginRight: "1rem", borderRadius: "4px" }}
            >
              <Typography.Title level={5} style={{ marginTop: "0.8rem" }}>
                <SettingFilled /> {intl.formatMessage({ id: `settingPage.basicSetting` })}
              </Typography.Title>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "pink",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                <Typography.Title level={5} style={{ marginTop: "0.2rem" }}>
                  <SettingFilled /> {intl.formatMessage({ id: `settingPage.systemSetting` })}
                </Typography.Title>
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem" }} vertical>
                <Text style={{ marginTop: "0.2rem" }}>- {intl.formatMessage({ id: `settingPage.systemSetting` })}</Text>
                <Flex>
                  <Button block style={{ marginRight: "1rem" }}>
                    Line Group
                  </Button>
                  <Button block type="primary">
                    Web Hook
                  </Button>
                </Flex>
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Text style={{ marginTop: "0.2rem" }}>- Channel Secret</Text>
                <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text>
                <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.copyCodeLine` })} prefix={<SettingFilled />} />
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Text style={{ marginTop: "0.2rem" }}>- Channel Secret</Text>
                <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text>
                <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.copyCodeLine` })} prefix={<SettingFilled />} />
              </Flex>
            </Flex>

            <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem" }} vertical>
              <Text style={{ marginTop: "0.2rem" }}>- {intl.formatMessage({ id: `settingPage.connectToSystem` })}</Text>
              <Flex>
                <Button block type="primary" style={{ marginRight: "1rem" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
                <Button block type="primary">
                {intl.formatMessage({ id: `settingPage.testConnection` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "#957DAD",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex justify="space-between" align="center">
                <Typography.Title level={5} style={{ marginTop: "0.2rem", marginBottom: "1rem", marginLeft: "1rem" }}>
                  <SettingFilled /> {intl.formatMessage({ id: `settingPage.setExpenses` })}
                </Typography.Title>
                <Button icon={<SettingFilled />} style={{ marginRight: "1rem" }}>
                  สงสัยไหม คืออะไร
                </Button>
              </Flex>
              <Flex
                style={{
                  background:
                    "linear-gradient(to right, #6b98d1, #78acd7, #8dbfdc, #a8d0e0, #c7e1e7, #c7e1e7, #c7e1e7, #c7e1e7, #a8d0e0, #8dbfdc, #78acd7, #6b98d1)",
                  height: "13rem",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.5rem",
                  marginBottom:"0.7rem",
                  borderRadius: "5px",
                }}
                justify="center"
                align="center"
                vertical
              >
                <Typography.Title level={2} style={{ margin: "0", marginBottom: "1rem", fontSize: fontSize }}>
                  <SettingFilled /> {intl.formatMessage({ id: `settingPage.lockFeature` })}
                </Typography.Title>
                <Typography.Title level={3} style={{ margin: "0", marginBottom: "1rem", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.unlockFeature` })}
                </Typography.Title>
                <Button style={{ fontSize: fontSize }}>
                  <SettingFilled />
                  {intl.formatMessage({ id: `settingPage.payForUnlock` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "green",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex justify="space-between" align="center">
                <Typography.Title level={5} style={{ marginTop: "0.2rem", marginBottom: "1rem", marginLeft: "1rem" }}>
                  <SettingFilled />  {intl.formatMessage({ id: `settingPage.settingGroup` })}
                </Typography.Title>
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Text style={{ marginTop: "0.2rem" }}>- Group ID (ระบบไลน์กลุ่ม ถ้าไม่ใช้ ห้ามใส่เด็ดขาด)</Text>
                <Text type="secondary">วิธีการเอา Group ID ให้ดึง OA ของท่านเข้ากลุ่ม แล้วพิมพ์คำสั่ง /thunder-start</Text>
                <Input size="large" placeholder="วิธีการเอา Group ID ให้ดึง OA ของท่านเข้ากลุ่ม แล้วพิมพ์คำสั่ง /thunder-start" />
                <Button block type="primary" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "orange",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex justify="space-between" align="center">
                <Typography.Title level={5} style={{ marginTop: "0.2rem", marginBottom: "1rem", marginLeft: "1rem" }}>
                  <SettingFilled /> {intl.formatMessage({ id: `settingPage.settingForNotification` })}
                </Typography.Title>
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Text style={{ marginTop: "0.2rem" }}>- Line Notify(Token)</Text>
                <Text type="secondary"> {intl.formatMessage({ id: `settingPage.gennerateTokenFrom` })}</Text>
                <Input size="large" placeholder="" />
                <Button block type="primary" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "orange",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex justify="space-between" align="center">
                <Typography.Title level={5} style={{ marginTop: "0.2rem", marginBottom: "1rem", marginLeft: "1rem" }}>
                  <SettingFilled />{intl.formatMessage({ id: `settingPage.settingForSendTextTo` })}
                </Typography.Title>
              </Flex>
              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0" }}>
                {intl.formatMessage({ id: `settingPage.slipIsCorrect` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.chatOA` })}</Radio.Button>
                  <Radio.Button value="b">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0" }}>
                {intl.formatMessage({ id: `settingPage.slipBeingUsed` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.chatOA` })}</Radio.Button>
                  <Radio.Button value="b">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0" }}>
                {intl.formatMessage({ id: `settingPage.slipCanNotSeeOrExpiration` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.chatOA` })}</Radio.Button>
                  <Radio.Button value="b">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0" }}>
                {intl.formatMessage({ id: `settingPage.slipNotEnough` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid">
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.chatOA` })}</Radio.Button>
                  <Radio.Button value="b">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.canNotQRFromPicture` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.chatOA` })}</Radio.Button>
                  <Radio.Button value="b">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
                </Radio.Group>
              </Flex>
              <Flex style={{ paddingLeft: "1rem", marginRight: "1rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                <Button block type="primary">
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "red",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex justify="space-between" align="center">
                <Typography.Title level={5} style={{ marginTop: "0.2rem", marginBottom: "1rem", marginLeft: "1rem" }}>
                  <SettingFilled /> {intl.formatMessage({ id: `settingPage.settingForCheckSystem` })}
                </Typography.Title>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.openForCheckSlipDuplicate` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.openForCheckGroup` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Text type="secondary"> {intl.formatMessage({ id: `settingPage.wornningYourAmountIsLow` })}</Text>
                <Input size="large" placeholder="" />
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.hiddenAccountForTranfer` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex
                vertical={breakpoints.xs ? true : false}
                justify="space-between"
                align={breakpoints.xs ? "" : "center"}
                style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
              >
                <Typography.Title level={5} style={{ margin: "0", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.hiddenAccountForTranferDestination` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
                </Radio.Group>
              </Flex>

              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Button block type="primary" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              style={{
                border: "0.5px",
                borderStyle: "double",
                borderColor: "purple",
                marginTop: "0.8rem",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
              vertical
            >
              <Flex justify="space-between" align="center">
                <Typography.Title level={5} style={{ marginTop: "0.5rem", marginBottom: "1rem", marginLeft: "1rem" }}>
                  <SettingFilled /> {intl.formatMessage({ id: `settingPage.settingBankAccount` })}
                </Typography.Title>
                <Button style={{ marginRight: "1rem" }}>{intl.formatMessage({ id: `settingPage.addBankAccount` })}</Button>
              </Flex>

              <Flex vertical style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                <Text>**เพิ่มเติม โปรดใช้ ชื่อบัญชี จากธนาคารเท่านั้น</Text>
                <Text>**เพิ่มเติม โปรดใช้ ชื่อบัญชี จากธนาคารเท่านั้น</Text>
                <Text>**เพิ่มเติม โปรดใช้ ชื่อบัญชี จากธนาคารเท่านั้น</Text>
                <Text>**เพิ่มเติม โปรดใช้ ชื่อบัญชี จากธนาคารเท่านั้น</Text>
                <Text>**เพิ่มเติม โปรดใช้ ชื่อบัญชี จากธนาคารเท่านั้น</Text>
              </Flex>

              <Flex
                vertical
                style={{
                  border: "1px",
                  borderStyle: "double",
                  borderColor: "gray",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.8rem",
                  borderRadius: "5px",
                }}
              >
                <Flex justify="space-between" align="center" style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "0.5rem" }}>
                  <Text>#บัญชีที่ 1</Text>
                  <SettingFilled />
                </Flex>

                <Flex
                  vertical={breakpoints.xs ? true : false}
                  justify="space-evenly"
                  align={breakpoints.xs ? "" : "center"}
                  style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "0.8rem", marginBottom: "1rem" }}
                >
                  <Col flex="1 0 33%" style={{ marginBottom: marginBottonXS }}>
                    <Flex vertical>
                      <Text>{intl.formatMessage({ id: `settingPage.bankProvider` })}</Text>
                      <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.bankProvider` })} />
                      <Text>{intl.formatMessage({ id: `settingPage.accountNumber` })}</Text>
                      <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.accountNumber` })} />
                    </Flex>
                  </Col>
                  <Col
                    flex="1 0 33%"
                    style={{
                      border: "1px",
                      borderStyle: "double",
                      borderColor: "gray",
                      borderRadius: "5px",
                      marginRight: marginRight,
                      marginBottom: marginBottonXS,
                    }}
                  >
                    <Flex vertical style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                      <Text>{intl.formatMessage({ id: `settingPage.thaiNameAccount` })}</Text>  
                      <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.thaiNameAccount` })} />
                      <Text>{intl.formatMessage({ id: `settingPage.engNameAccount` })}</Text>
                      <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.engNameAccount` })} />
                    </Flex>
                  </Col>
                  <Col flex="1 0 33%" style={{ border: "1px", borderStyle: "double", borderColor: "gray", borderRadius: "5px" }}>
                    <Flex vertical style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                      <Text>{intl.formatMessage({ id: `settingPage.engNameAccount` })}</Text>
                      <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.engNameAccount` })} />
                      <Text>{intl.formatMessage({ id: `settingPage.engNameAccount` })}</Text>
                      <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.engNameAccount` })} />
                    </Flex>
                  </Col>
                </Flex>
              </Flex>
              <Flex>
                <Button block type="primary" style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Flex>
            </Flex>

            <Flex
              vertical
              style={{
                border: "1px",
                borderStyle: "double",
                borderColor: "green",
                marginLeft: "1rem",
                marginRight: "1rem",
                marginTop: "0.8rem",
                borderRadius: "5px",
              }}
            >
              <Text style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>  {intl.formatMessage({ id: `settingPage.settings` })}</Text>
              <Flex justify="space-between" align="center" style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                <Typography.Title style={{ margin: "0", fontSize: fontSize }} level={5}>
                {intl.formatMessage({ id: `settingPage.protectWebhook` })}
                </Typography.Title>
                <Radio.Group defaultValue="a" buttonStyle="solid" style={{ fontSize: fontSize }}>
                  <Radio.Button value="a">{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                  <Radio.Button value="c">{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
                </Radio.Group>
              </Flex>
              <Flex>
                <Button block type="primary" style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Col>
    </>
  );
};

export default memo(Homepage);