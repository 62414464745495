import React, { useEffect, useState } from "react";
import { Button, Badge, Flex, Form, Input, Modal, Switch, Tag, Divider } from "antd";
import { useIntl } from "react-intl";
import "./SettingSection.scss";
import { Calendar } from "react-feather";
import { UserShop } from "shared/dist";
import { getDateFormatString } from "utils/date";
import FeatherIcon from "feather-icons-react";

interface SettingSectionProps {
  shop: UserShop;
  onSubmit(data: Partial<UserShop>): Promise<void>;
  onShopChange: (data: Partial<UserShop>) => Promise<void>;
};

export const SettingSection: React.FC<SettingSectionProps> = ({ shop, onSubmit, onShopChange }) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState(0)
  const showModal = (titleMessage: string, sendDataMode: number) => {
    setMode(sendDataMode);
    setTitle(titleMessage);
    setIsModalOpen(true);
  };

  const handelOk = async (data: Partial<UserShop>) => {
    await onShopChange(data);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: Partial<UserShop>) => {
    console.log("Received values of form: ", values);
    await onSubmit(values);
  };

  useEffect(() => {
    console.log(`SettingSection[shop]: `, shop)
    form.setFieldsValue({shopName: shop.shopName, isAutoRenewed: shop.isAutoRenewed, isAutoTopUp: shop.isAutoTopUp});
  }, [shop.shopName, shop.isAutoRenewed, shop.isAutoTopUp])

  return (
    <div className="SettingSection">
      <div className="custom-header-style content-setting-section" >
        <Form
          form={form}
          name="basic"
          initialValues={{ shopName: shop.shopName, isAutoRenewed: shop.isAutoRenewed, isAutoTopUp: shop.isAutoTopUp }}
          onFinish={onFinish}
          autoComplete="off"
          // layout="vertical"
        >
          <div className="content-shop-detail">
            <div className="header-title">
              <h2>รายละเอียด</h2>
            </div>
          <Form.Item
            label={intl.formatMessage({ id: `shopPage.shopName` })}
            name="shopName"
            rules={[{ required: true, message: "Please input your contract" }]}
          >
            <Input size="large" placeholder={intl.formatMessage({ id: `shopPage.shopName` })} />
         
          </Form.Item>
          <div className="detail-shop-product">
            <div className="product-txt">
              <div className="labe-txt">{intl.formatMessage({ id: `shopPage.product` })} :</div>
              <div>{shop?.userPackage?.creditPackage?.description}</div> 
            </div>
            <div className="open-txt">
              <div className="labe-txt">
                {intl.formatMessage({ id: `shopPage.service` })} :
              </div>
              <div>
                {shop.shopIsActive ? <Badge status="success" text={intl.formatMessage({ id: `shopPage.active` })} />  : <Badge status="error" text={intl.formatMessage({ id: `shopPage.inactive` })} />}
              </div>
            </div>
          </div>
          {shop?.userPackage?.expiredAt && <Flex justify="space-between" align="center">
            <p>
              <Calendar size={14} /> {intl.formatMessage({ id: `shopPage.expiration` })}
            </p>
            <p>{getDateFormatString(shop.userPackage.expiredAt)}</p>
          </Flex>}
          <Divider />
          <div className="header-title">
            <h2>{intl.formatMessage({ id: `shopPage.automaticRenewal` })} </h2>
          </div>
             <div className="detail-shop-product">
              <div className="product-txt">
                <div className="labe-txt">
                  {intl.formatMessage({ id: `shopPage.expirationDate` })}
                </div>
                <div>
                  <Form.Item name="isAutoRenewed" className="mb-0">
                    <Switch defaultValue={false} defaultChecked={false}/>
                  </Form.Item>
                </div>
              </div>
              <div className="product-txt">
                <div className="labe-txt">
                  {intl.formatMessage({ id: `shopPage.beyondUse` })}
                </div>
                <Form.Item name="isAutoTopUp"  className="mb-0">
                  <Switch defaultValue={false} defaultChecked={false}/>
                </Form.Item>
              </div>
            </div>
            <Button onClick={() => showModal(intl.formatMessage({ id: shop.shopIsActive ? `shopPage.temporarilyDisabled` : `shopPage.temporarilyEnabled` }), 1)} type="dashed"  icon={shop.shopIsActive ? <FeatherIcon icon="lock" /> : <FeatherIcon icon="unlock" />}
            // style={shop.shopIsActive ? 
            //   { background: "orange", borderColor: "orange", marginRight: "1rem" } : 
            //   { background: "green", borderColor: "green", marginRight: "1rem" }}>
            className={shop.shopIsActive ? "button-line-black"  : "button-line-black" } >
              {intl.formatMessage({ id: shop.shopIsActive ? `shopPage.temporarilyDisabled` : `shopPage.temporarilyEnabled` })}
            </Button>
          </div>
        
          <div className="setting-button-control">
            <Button size="large" block type="primary" htmlType="submit"  className="button-main">
              {intl.formatMessage({ id: `shopPage.saveData` })}
            </Button>
            <Button size="large" className="button-main-line" block type="primary" htmlType="submit" disabled>
              <FeatherIcon icon="plus" />
              {intl.formatMessage({ id: `shopPage.renew` })}
            </Button>
            <Button size="large" block className="button-main-line" type="primary" htmlType="submit" disabled>
              <FeatherIcon icon="repeat" />
              {intl.formatMessage({ id: `shopPage.changePackage` })}
            </Button>
            <div></div>
            <Button size="large"  onClick={() => showModal("ต้องการจะลบร้านค้าใช่ไหม", 2)} danger type="text" className="button-text-danger" disabled={shop.isDeleted ? true : false}  >
                <FeatherIcon icon="trash-2" />
                {intl.formatMessage({ id: `shopPage.deleteShop` })}
            </Button>
          </div>
          </Form>
      </div>
      <Modal title={title} open={isModalOpen} onOk={() => handelOk(mode === 1 ? { shopIsActive: !shop.shopIsActive } : { isDeleted: !shop.isDeleted })} onCancel={handleCancel}></Modal>
    </div>
  );
};
