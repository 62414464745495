import { UserBranch } from "shared/dist";
import { api } from "./base";

export const USER_BRANCH = "/user-branch";
export const USER_BRANCH_DETAIL = `${USER_BRANCH}/detail/:id`;
export const USER_BRANCH_DETAIL_CREATE_URL = `${USER_BRANCH}/create`;
export const USER_BRANCH_DETAIL_UPDATE_URL = `${USER_BRANCH}/update/:id`;

export async function getUserBranchDetail(id: string) {
  const response = await api.get(USER_BRANCH_DETAIL.replace(":id", id))
  return response.data
}

export async function createUserBranch(branchName: string, shopId: number ) {
  const response = await api.post(USER_BRANCH_DETAIL_CREATE_URL, { branchName, shopId } );
  return response.data;
}

export async function updateUserBranch(id: string | number, data: Partial<UserBranch>) {
  const response = await api.put(USER_BRANCH_DETAIL_UPDATE_URL.replace(":id", `${id}`), data );
  return response.data;
}