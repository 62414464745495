import { AxiosError } from "axios"
import useSWR, { SWRConfiguration } from "swr"

import { apiFetcher } from "./fetchers"

export function createService<Data>(key: string) {
  return {
    useSWR(
      config?: SWRConfiguration,
      identity?: { key: string; value?: string | number | null },
    ) {
      // console.log(`key: `, key)
      // console.log(`config: `, config)
      // console.log(`identity: `, identity)
      return useSWR<Data, AxiosError<any>>(() => {
        if (identity && !identity.value) {
          return null
        }
        if (identity && identity.value) {
          return key.replace(identity.key, String(identity.value))
        }
        return key
      }, config)
    },
    fetchSS() {
      return apiFetcher<Data>(key)
    },
  }
}
