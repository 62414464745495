import React from "react";
import "./SettingTag.scss"
import { RegularSection } from "./RegularSection";
import { SystemSection } from "./SystemSection";
import { UserBranch } from "shared";
import { Card } from "antd";

interface SettingTagProps {
  branch: UserBranch
  onBranchChange: (values: Partial<UserBranch>) => Promise<void>
}

export const SettingTag: React.FC<SettingTagProps> = ({ branch, onBranchChange }) => {

  return (
    <Card className="setting-tag-card">
      <div className="setting-tag">
        <RegularSection branch={branch} onBranchChange={onBranchChange} />
        <SystemSection branch={branch} onBranchChange={onBranchChange} />
      </div>
    </Card>
  );
};
