import { memo } from 'react'
import { Select, Space } from 'antd';
import styled from 'styled-components';
import thFlag from 'assets/nation-flag/th.png';
import enFlag from 'assets/nation-flag/en.png';

const { Option } = Select;

const LangSwitch = (props: any) => {
  return (
    <Select {...props}>
      <Option value="th">
        <Space>
          <NationFlag src={thFlag} alt="thai language" />
          <span className="">TH</span>
        </Space>
      </Option>
      <Option value="en">
        <Space>
          <NationFlag src={enFlag} alt="english language" />
          <span className="">EN</span>
        </Space>
      </Option>
    </Select>
  )
}

export default memo(LangSwitch);

const NationFlag = styled.img`
  width: 23.33px;
  height: 18px;
  padding-top: 6px;
`