import React from "react";
import "./LineOAFrom.scss";
import { Form, Input, Row, Col, Flex, Typography, Space, Button, message } from "antd";
import { useIntl } from "react-intl";
import { SettingFilled, CopyOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard"
import IntlMessages from "components/IntlMessages";
import { UserBranch } from "shared/dist";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

interface LineOAFromProps {
  reference: String,
  channelId: string,
  userbranch: UserBranch
}

export const LineOAFrom: React.FC<LineOAFromProps> = ({ reference, channelId = ''}) => {
  const hookApiUrl = `${process.env?.REACT_APP_API_BASE_URL}/hook/oa/${reference}`
  const intl = useIntl();
  const { Text } = Typography;
  const handelCopyReference = () => {
    copy(hookApiUrl)
    message.success(intl.formatMessage({ id: `message.copyEntitySuccess` }, { entity: hookApiUrl }))
  }

  return (
    <div className="line-oa-form" >
        <Form.Item>
        <label>{intl.formatMessage({ id: `branchPage.oaWebhookApi` })}</label>
          <Space.Compact style={{ width: '100%' }}>
            <Input className="testobj" size="large" defaultValue={hookApiUrl} disabled />
            <Button size="large" className="button-main" type="primary" onClick={handelCopyReference}><CopyOutlined /><IntlMessages id="common.copy" /> </Button>
          </Space.Compact>
         </Form.Item>
        {/* <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text> */}
        <Form.Item
          name="lineChannelSecret"
          className="channel-secret"
          rules={[{ required: true, message: intl.formatMessage({ id: `message.pleaseInputFiled` }, { filed: "Channel Secret" }) }]}
            >
              <label>Channel ID</label>
              <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.copyCodeLine` })} prefix={<FeatherIcon icon="tool" />} />
            </Form.Item>

            {/* <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text> */}
            <Form.Item
              name="lineChannelToken"
              className="channel-access-token mb-0"
              rules={[{ required: true, message: intl.formatMessage({ id: `message.pleaseInputFiled` }, { filed: "Channel Access Token" }) }]}
            >
              <label>Channel Secret</label>
              <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.copyCodeLine` })} prefix={<FeatherIcon icon="tool" />} />
            </Form.Item>


      <Row>
        {/* <Col span={24}>
          <Flex vertical>
            <Text className="title">{intl.formatMessage({ id: `branchPage.oaWebhookApi` })}</Text>
            <Space.Compact style={{ width: '100%' }}>
              <Input size="large" defaultValue={hookApiUrl} disabled />
              <Button size="large" type="primary" onClick={handelCopyReference}><CopyOutlined /><IntlMessages id="common.copy" /> </Button>
            </Space.Compact>
          </Flex>
        </Col> */}
        {/* <Col span={24} style={{ paddingBottom: '0px' }}>
          <Flex vertical>
            <Text className="title">- Channel ID</Text>
            <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text>
            <Form.Item
              name="lineChannelSecret"
              className="channel-secret"
              style={{ marginBottom: '0px !important' }}
              rules={[{ required: true, message: intl.formatMessage({ id: `message.pleaseInputFiled` }, { filed: "Channel Secret" }) }]}
            >
              <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.copyCodeLine` })} prefix={<SettingFilled />} />
            </Form.Item>
          </Flex>
        </Col> */}
        {/* <Col span={24} style={{ paddingBottom: '0px' }}>
          <Flex vertical>
            <Text className="title">- Channel Secret</Text>
            <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text>
            <Form.Item
              name="lineChannelToken"
              className="channel-access-token"
              rules={[{ required: true, message: intl.formatMessage({ id: `message.pleaseInputFiled` }, { filed: "Channel Access Token" }) }]}
            >
              <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.copyCodeLine` })} prefix={<SettingFilled />} />
            </Form.Item>
          </Flex>
        </Col> */}
      </Row>
      {channelId && (
        <Row className="contect-how-to">
          <Col span={24}>
            <Text >1. คลิกลิงก์ Line Developer
            <div>
              <Link to={`https://developers.line.biz/console/channel/${channelId}/messaging-api`} target="_blank">https://developers.line.biz/console/channel/2005658406/messaging-api</Link>
            </div>
            </Text>
           
          </Col>
          <Col span={24}>
            <Text>2. เลื่อนลงมาหาคำว่า Channel access Token(long-lived) แล้วกดปุ่ม issue เพื่อรับ Token</Text>
          </Col>
          <Col span={24}>
            <Text>3. คัดลอก Token ที่แสดงแล้วนำมากรอกในช่องด่านล่าง</Text>
          </Col>
          <Col span={24}>
            <Flex vertical className="channel-access-token">
            <Text className="title">Channel Access Token (Long lived)</Text>
              <Text type="secondary">{intl.formatMessage({ id: `settingPage.copyCodeLine` })}</Text>
              <Form.Item
                name="lineChannelTokenLongLive"
                className=" mb-0"
                rules={[{ required: true, message: intl.formatMessage({ id: `message.pleaseInputFiled` }, { filed: "Channel Access Token" }) }]}
              >
                <Input size="large" placeholder="xxxxx-xxx-xx" prefix={<SettingFilled />} />
              </Form.Item>
            </Flex>
          </Col>
          <Col span={24}>
            <Text>4. พร้อมใช้งาาน</Text>
          </Col>
        </Row>
      ) 
      }

    </div>
  );
};
