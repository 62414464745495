// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page .header-title-customs .subtitle {
  color: #8b8b8b !important;
}
.home-page .ant-table-thead > tr > th {
  background: #ECEEF2 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/Home/Home.scss"],"names":[],"mappings":"AAEI;EACE,yBAAA;AADN;AAKE;EACE,8BAAA;AAHJ","sourcesContent":[".home-page{\n  .header-title-customs{\n    .subtitle {\n      color: #8b8b8b !important;\n    }\n  }\n  \n  .ant-table-thead>tr>th {\n    background: #ECEEF2 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
