import { memo } from "react";
import { Button, Alert, Form, Input, Row, ColProps, RowProps, Col } from "antd";
import "./ProfileFrom.scss";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { useUserAccount, changePassword } from "api/useUserAccount";
import { UserAccount } from "shared/dist"
import { useNotification } from "components/Notification";

interface ProfileFromProps {
  form: any,
}

const ProfileFrom: React.FC<ProfileFromProps> = ({ form }) => {
  const intl = useIntl();
  const { account, updateUserAccount } = useUserAccount();
  const [changePasswordForm] = Form.useForm();
  const { notification, contextNotification } = useNotification();

  const onFinish = async (data: Partial<UserAccount>) => {
    console.log("Received values of form: ", data);
    try {
      await updateUserAccount(String(account.id), data);
      notification.success({
        message: <IntlMessages id="success" />,
        description: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `profilePage.entity` }) }),
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
    }
    // onSubmit(values)
  };

  const updatePassword = async (data: any) => {
    try {
      if (data.newPasswordReq !== data.confirmPasswordReq) {
        notification.error({
          message: <IntlMessages id="failed" />,
          description: intl.formatMessage({ id: 'common.somethingWasWrong' }),
          placement: 'topRight',
        });
        return;
      }

      await changePassword(String(account.id), data);
      notification.success({
        message: <IntlMessages id="success" />,
        description: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `profilePage.password` }) }),
        placement: 'topRight',
      });
    } catch (error) {
      console.error('Failed to Update Password:', error);
      notification.error({
        message: <IntlMessages id="failed" />,
        description: intl.formatMessage({ id: `message.somethingWasWrong` }),
        placement: 'topRight',
      });
    }
  };

  const onValidateNewPassword = (rule: any, value: any, callback: any) => {
    const currentPassword = changePasswordForm.getFieldValue('currentPassword');

    if (value && value === currentPassword) {
      callback(intl.formatMessage({ id: 'profilePage.avoidCurrentPassword' }));
    } else {
      callback();
    }
  };

  const onValidateConfirmPassword = (rule: any, value: any, callback: any) => {
    const newPassword = changePasswordForm.getFieldValue('newPassword');

    if (value && value !== newPassword) {
      callback(intl.formatMessage({ id: 'common.passwordNotMatch' }));
    } else {
      callback();
    }
  };

  type FieldType = {
    firstName?: string;
    lastName?: string;
    contact?: string;
    phone?: number;
    password?: string;
  };

  const colStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xxl: { span: 12 },
  };
  const rowStyle: RowProps = { gutter: { xs: 8, sm: 16, md: 24, lg: 32 } };

  return (
    <>
      <div className="profile-from">
        <div className="content-information">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div className="header-profile">
              <h2>
                <IntlMessages id="profilePage.settings" />
              </h2>
            </div>
            <div className="space-alert">
              <Alert message={<IntlMessages id="profilePage.detailSettingTelephone" />} type="success" showIcon />
            </div>

            <Row {...rowStyle}>
              <Col {...colStyle}>
                <Form.Item<FieldType>
                  hasFeedback validateStatus="success"
                  label={intl.formatMessage({ id: `profilePage.name` })}
                  name="firstName"
                  rules={[{ required: true, message: "Please input your name" }]}
                >
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: `profilePage.name` })}
                  />
                </Form.Item>
              </Col>
              <Col {...colStyle}>
                <Form.Item<FieldType>
                  hasFeedback validateStatus="success"
                  label={intl.formatMessage({ id: `profilePage.lastname` })}
                  name="lastName"
                  rules={[{ required: true, message: "Please input your password" }]}
                >
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: `profilePage.lastname` })}

                  />
                </Form.Item>
              </Col>
              <Col {...colStyle}>
                <Form.Item<FieldType>
                  hasFeedback validateStatus="success"
                  label={intl.formatMessage({ id: `profilePage.telephoneNumber` })}
                  name="phone"
                  rules={[{ required: true, message: "Please input your phone" }]}
                >
                  <Input
                    size="large"
                    disabled
                    placeholder={intl.formatMessage({ id: `profilePage.telephoneNumber` })}
                  // prefix={<PhoneOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col {...colStyle}>
                <Form.Item<FieldType>
                  hasFeedback validateStatus="success"
                  label={intl.formatMessage({ id: `profilePage.lineId` })}
                  name="contact"
                  rules={[{ required: true, message: "Please input your contract" }]}
                >
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: `profilePage.lineId` })}
                  // prefix={<ContactsOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Button size="large" className="button-main" type="primary" htmlType="submit">
              <IntlMessages id="profilePage.update" />
            </Button>
          </Form>
        </div>
        {/* <Divider /> */}
        <div className="content-security">
          <Form
            form={changePasswordForm}
            //name="changePasswordForm"
            //initialValues={{ remember: true }}
            onFinish={updatePassword}
            //  onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="header-profile ">
              <h2>
                <IntlMessages id="profilePage.security" />
              </h2>
            </div>
            <Row {...rowStyle}>
              {/* <Col {...colStyle}>
                <Form.Item<FieldType> label="อีเมล">
                  <Input size="large" placeholder="อีเมล" />
                </Form.Item>
              </Col>
              */}
              <Col {...colStyle} >
                <Form.Item
                  label={intl.formatMessage({ id: `profilePage.currentPassword` })}
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input current password",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    className=""
                    type="password"
                    placeholder={intl.formatMessage({ id: `profilePage.currentPassword` })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row {...rowStyle}>

              <Col {...colStyle}>
                <Form.Item
                  label={intl.formatMessage({ id: `profilePage.newPassword` })}
                  name="newPassword"
                  rules={[{ required: true, message: "Please input new password" }, { validator: onValidateNewPassword }]}
                >
                  <Input.Password
                    size="large"
                    type="password"
                    placeholder={intl.formatMessage({ id: `profilePage.newPassword` })}
                  />
                </Form.Item>
              </Col>

              <Col {...colStyle}>
                <Form.Item
                  label={intl.formatMessage({ id: `profilePage.confirmPassword` })}
                  name="confirmPassword"
                  rules={[{ required: true, message: "Please confirm new password" }, { validator: onValidateConfirmPassword }]}
                >
                  <Input.Password
                    size="large"
                    type="password"
                    placeholder={intl.formatMessage({ id: `profilePage.confirmPassword` })}
                  />
                </Form.Item>
              </Col>

            </Row>
            <Button type="primary" size="large" className="button-main" htmlType="submit"
            // icon={<FeatherIcon icon="home" />}
            >
              {intl.formatMessage({ id: `profilePage.updatePassword` })}
            </Button>
          </Form>
        </div>
      </div>
      {contextNotification}
    </>
  );
};

export default memo(ProfileFrom);
