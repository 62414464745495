import React from "react";
import { Form, Input, Button, Typography, Spin } from "antd";
import FeatherIcon from "feather-icons-react";
import { UserAccount } from "shared/dist";
import { useIntl } from "react-intl";
import lodash from "lodash";
import IntlMessages from "components/IntlMessages";

const { Link } = Typography;

interface RegisterFromProps {
  onSubmit(data: Partial<UserAccount>): void;
  isLoading: boolean
}

export const RegisterFrom: React.FC<RegisterFromProps> = ({ onSubmit, isLoading }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const onFinish = (values: UserAccount) => {
    console.log("Received values of form: ", values);
    const userAccount = {
      id: 0,
      ...lodash.omit(values, ['confirmPassword'])
    }
    onSubmit(userAccount);
  };

  const onValidateConfirmPassword = (rule: any, value: any, callback: any) => {
    const password = form.getFieldValue('password');
    if (value && value !== password) {
      callback(intl.formatMessage({ id: 'common.passwordNotMatch' }));
    } else {
      callback();
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        {/* <img src={logoCheckslip} className="brand-logo-checkslip" /> */}
        <h2><IntlMessages id="registerPage.signUp" /></h2>
        <div className="sub-title">
          <IntlMessages id="registerPage.haveAccount" /> &nbsp;
          <Link className="text-login-form" href="/auth/sign-in">
            Sign In
          </Link>
        </div>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your Phone Number!",
            },
          ]}
        >
          <Input
            size="large"
            prefix={<FeatherIcon icon="smartphone" className="site-form-item-icon-custom" />}
            placeholder="Phone Number"
          />
        </Form.Item>
        {/* <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          type: "email",
          message: "The input is not valid E-mail!",
        },
        {
          required: true,
          message: "Please input your Email!",
        },
      ]}
    >
      <Input
        size="large"
        prefix={<FeatherIcon icon="mail" className="site-form-item-icon-custom" />}
        placeholder="Email"
      />
    </Form.Item> */}
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input
            size="large"
            prefix={<FeatherIcon icon="user" className="site-form-item-icon-custom" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<FeatherIcon icon="lock" className="site-form-item-icon-custom" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input your Confirm Password!",
          //   },
          // ]}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: 'message.pleaseInputFiled' })} ${intl.formatMessage({ id: 'registerPage.confirmPassword' })}!`
            },
            { validator: onValidateConfirmPassword }
          ]}
        >
          <Input.Password
            size="large"
            prefix={<FeatherIcon icon="lock" className="site-form-item-icon-custom" />}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit" className="w-100 button-main">
            <IntlMessages id="registerPage.signUp" />
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
