// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.branch-overview-tab {
  width: "100%";
}
@media (max-width: 480px) {
  .branch-overview-tab .ant-card-body {
    padding: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/components/Branch/BranchOverviewTab/BranchOverviewTab.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE;IACE,YAAA;EACJ;AACF","sourcesContent":[".branch-overview-tab {\n  width: \"100%\";\n\n  @media (max-width: 480px) {\n    .ant-card-body{\n      padding: 0px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
