import { api } from "./base";

// TODO: Find where to keep these constants
export const LOGIN_URL = "/auth/signin";
export const LOGOUT_URL = "/auth/signout";

export const ACCESS_TOKEN_KEY = "accessToken";
export const LOCALE_KEY = "locale";
export const MENU_SELECTED_KEY = "menuSelected";

export async function login(username: string, password: string) {
  return api.post(LOGIN_URL, { username, password }).then((res) => {
    setToken(res?.data?.token);
    setLocale('th');
    setMenuSelected('/');
    return res?.data?.token as string;
  });
}

export async function logout() {
  await api.post(LOGOUT_URL)
  clearTokens()
  window.location.href = '/auth/sign-in'
}

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

export const setToken = (accessToken = "") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(accessToken));
};

export const getAccessToken = () => {
  if (typeof window === "undefined") return ""

  const jsonString = localStorage.getItem(ACCESS_TOKEN_KEY)

  return jsonString ? JSON.parse(jsonString) : undefined
}

export const setLocale = (locale = "th") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(LOCALE_KEY, locale);
};

export const getLocale = () => {
  if (typeof window === "undefined") return "th"

  const local = localStorage.getItem(LOCALE_KEY) ?? "th"

  return local
}

export const getMenuSelected = () => {
  if (typeof window === "undefined") return "home"
  const value = localStorage.getItem(MENU_SELECTED_KEY) ?? "home"
  return value
}

export const setMenuSelected = (locale = "home") => {
  if (typeof window === "undefined") return;
  localStorage.setItem(MENU_SELECTED_KEY, locale);
};