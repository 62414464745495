import React from "react";
import "./RegularSection.scss"
import { Row, Col } from "antd";
import type { ColProps } from "antd";
import { DetailSection } from "./DetailSection";
import { SettingSection } from "./SettingSection";
import { UserBranch } from "shared/dist";
import { GroupSection } from "./GroupSection";

interface RegularSectionProps {
  branch: UserBranch
  onBranchChange: (values: Partial<UserBranch>) => Promise<void>
}

export const RegularSection: React.FC<RegularSectionProps> = ({ branch, onBranchChange }) => {

  return (
    <div className="regular-section">
      <Row>
        {/* <Col {...colStyle} >
          <DetailSection
            branch={branch}
            onIsActiveShopChange={onBranchChange}
            onDeleteShop={onBranchChange}
          />
        </Col>
        <Col {...colStyle} >
          <SettingSection branch={branch} onSubmit={onBranchChange} />
        </Col> */}
        <Col span={24}>
          <GroupSection
            branch={branch}
            onSubmit={onBranchChange}
            onIsActiveShopChange={onBranchChange}
            onDeleteShop={onBranchChange}
          />
        </Col>
      </Row>
    </div>
  );
};
