import React from "react";
import "./LineGroupFrom.scss";
import { Input, Row, Col, Flex, Typography, Space, Button, Badge, Tag, message, Image, Grid , Card } from "antd";
import type { ColProps } from "antd";
import { useIntl } from "react-intl";
import { CopyOutlined, WechatOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard"
import IntlMessages from "components/IntlMessages";
import { UserBranch } from "shared/dist";
import { Link } from "react-router-dom";
import { LinkOutlined, DisconnectOutlined } from "@ant-design/icons";
import lineQrAddFriend from "assets/img/line/line-qr.png";
import FeatherIcon from "feather-icons-react";

interface LineGroupFromProps {
  reference: string
  userBranch: UserBranch
}

export const LineGroupFrom: React.FC<LineGroupFromProps> = ({ reference, userBranch }) => {
  const intl = useIntl();
  const { Text } = Typography;
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const handelCopyReference = () => {
    copy(`start:${reference}`)
    message.success(intl.formatMessage({ id: `message.copyEntitySuccess` }, { entity: `start:${reference}` }))
  }

  const colSmallStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
    xxl: { span: 8 },
  };

  const colMediumStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xxl: { span: 24 },
  };
  const colStyle = breakpoints.xs ? colSmallStyle : colMediumStyle;
  const imageQrStyle = breakpoints.xs ? 155 : "100%";

  return (
        <div className="line-group-form" >
          <label>รหัสอ้างอิง</label>
          <Space.Compact className="w-100 input-copy-line">
              <Input size="large" defaultValue={`start:${reference}`} disabled />
            <Button size="large" className="button-main" type="primary" onClick={handelCopyReference}><FeatherIcon icon="copy" /><IntlMessages id="common.copy" /> </Button>
          </Space.Compact>
          <div className="contect-line-group">
            <div className="contect-left">
              <div className="header-line-group">
                <h2>{intl.formatMessage({ id: `branchPage.howToConnect` })}</h2>
              </div>
              {/* <Image src={lineQrAddFriend} /> */}
              <div className="control-button-add-friends">
                <Link className="button-line-friends" type="button" target="_blank" to={`https://line.me/R/ti/p/${process.env.REACT_APP_LINE_BOT_BASIC_ID}?oat_content=url`} ><FeatherIcon icon="user-plus" /> Add Friends Bot</Link>
              </div>
                <ul>
                  <li>กดปุ่ม Add Friends Bot เพื่อเพิ่มเพื่อนบอท</li>
                  <li>สร้างไลน์กลุ่ม และ เชิญบอทเข้าร่วมกลุ่ม</li>
                  <li>คัดลอก รหัสอ้างอิง</li>
                  <li>เรียบร้อย, บอทของคุณจะเริ่มทำงาน</li>
                </ul>
            </div>
            <div className="contect-right ">
            <div className="header-line-group">
                <h2>{intl.formatMessage({ id: `branchPage.connectionStatus` })} </h2>
              </div>
              <ul>
                <li>
                  <div className="label-txt">
                    {intl.formatMessage({ id: `shopPage.status` })} : 
                  </div>
                  {userBranch?.lineGroupId ? (
                    <Tag color="cyan" className="mr-0" icon={<LinkOutlined />}>{intl.formatMessage({ id: `servicePage.ready` })}</Tag>
                     ) : (
                      <Tag color="volcano" className="mr-0" icon={<DisconnectOutlined />}>{intl.formatMessage({ id: `servicePage.notReady` })}</Tag>
                
                    )
                  }
              </li>
              <li>
                <div className="label-txt">
                  {intl.formatMessage({ id: `shopPage.service` })} :  
                </div>
                <Badge status="success" text={intl.formatMessage({ id: `shopPage.active` })} />
              </li>
              </ul>
            </div>
          </div>
    </div>
  );
};
