// import React from "react";
// import logo from "./logo.svg";
import "./App.css";
// import "../public/style/index.scss"
import "./style/index.scss";
// import { Button } from "antd";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Template } from "pages/Template";
import { Login } from "pages/Login";
import { Register } from "pages/Register";
import { useUserAccount } from "api/useUserAccount";
import { NotFound } from "pages/components/NotFound";

function App() {
  const { account } = useUserAccount()
  // console.log(`account: `, account);
  // console.log(process.env);

  return (
    <Router>
      <Routes>
        <Route path="/auth/sign-in" element={<Login />} />
        <Route path="/auth/sign-up" element={<Register />} />
        {account ? <Route path="*" element={<Template />} /> : <Route path="*" element={<NotFound />} />}
      </Routes>
    </Router>
  );
}

export default App;
