import { AxiosError, AxiosResponse } from "axios"

import { getAccessToken } from "./auth"
import { api } from "./base"

let refreshTokenPromise: Promise<void | AxiosResponse | AxiosError> | null

function handleCaseInvalidToken(error: AxiosError): boolean {
  return (
    error.response?.status === 401 &&
    (error.response?.data === "Invalid token" ||
      error.response?.data === "Missing token") 
    //   &&
    // error.config.url !== LOGIN_URL
  )
}

function handleCaseRefreshTokenApiError(error: AxiosError): boolean {
  return (
    error.response?.status === 400 &&
    error.response?.data === "Unable to refresh token" 
    // &&
    // error.config.url !== LOGIN_URL
  )
}

// function retryRefresh(
//   error: AxiosError,
// ): Promise<void | AxiosResponse | AxiosError> {
//   const accessToken = getAccessToken()

//   error.config.headers.Authorization = `Bearer ${accessToken}`

//   return api.request(error.config).catch(async (err: AxiosError) => {
//     if (handleCaseInvalidToken(err) || handleCaseRefreshTokenApiError(err)) {
//       await destroyUserSession()
//     }

//     return Promise.reject(error)
//   })
// }

api.interceptors.request.use(
  config => {
    const accessToken = getAccessToken()

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

// api.interceptors.response.use(
//   config => {
//     return config
//   },
//   async (error: AxiosError) => {
//     if (handleCaseInvalidToken(error)) {
//       if (!refreshTokenPromise) {
//         refreshTokenPromise = refresh()
//           .then(() => {
//             refreshTokenPromise = null
//             return retryRefresh(error)
//           })
//           .catch(async () => {
//             await destroyUserSession()
//           })
//       }

//       return refreshTokenPromise
//         .then(() => {
//           return retryRefresh(error)
//         })
//         .catch(async () => {
//           await destroyUserSession()
//         })
//     }

//     return Promise.reject(error)
//   },
// )

export { api }
