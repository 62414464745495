import React from "react";
import { Result, Button, Spin } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useUserAccount } from "api/useUserAccount";

export const NotFound: React.FC = () => {
    const { account, isLoading } = useUserAccount()
    const navigate = useNavigate()

    if(isLoading){
        return <Spin style={{ width: "100%" }} />
    }

    if(!isLoading && !account){
        navigate(`/auth/sign-in`)
        return <Spin style={{ width: "100%" }} />
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={account ? <Link to="/"><Button type="primary">Back Home</Button></Link> : <Link to="/auth/sign-in"><Button type="primary">Sign in</Button></Link>}
        />
    );
}