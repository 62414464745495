import React from "react";
import "./BankAccountItemCard.scss";
import { Button, Flex, Typography, Card } from "antd";
import type { ColProps } from "antd";
import { useIntl } from "react-intl";
import { BankAccount } from "shared/dist";
import FeatherIcon from "feather-icons-react";
import ScbBank from "assets/img/bank/scb.jpg";

interface BankAccountItemCardProps {
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  index: number
  bankAcount?: BankAccount
  mode: string
}

export const BankAccountItemCard: React.FC<BankAccountItemCardProps> = ({ index, bankAcount, onEdit, onDelete }) => {
  const intl = useIntl();
  const { Text } = Typography;

  const bankCodes: { [key : string]: string } = {
    "014": "SCB - ไทยพาณิชย์",
    "002": "BBL - กรุงเทพ",
    "004": "KBANK - กสิกรไทย",
    "006": "KTB - กรุงไทย",
    "011": "TTB - ทหารไทยธนชาต",
    "022": "CIMBT - ซีไอเอ็มบีไทย",
    "024": "UOBT - ยูโอบี",
    "025": "BAY - กรุงศรีอยุธยา",
    "030": "GSB - ออมสิน",
    "033": "GHB - ธ.อ.ส.",
    "034": "BAAC - ธ.ก.ส.",
    "042": "CIMB - ซีไอเอ็มบี",
    "058": "EXIM - ธ.ส.น.",
    "067": "TISCO - ทิสโก้",
    "069": "KKP - เกียรตินาคิน",
    "073": "LHBANK - แลนด์ แอนด์ เฮ้าส์"
  }

  if (!bankAcount) {
    return (<Card 
              loading title={<Text>{intl.formatMessage({ id: `branchPage.atAccount` }, { number: index })}</Text>} 
              style={{ width: "100%" }}
            />)
  }

  const { id, bankCode, accountNumber, firstNameTh, lastNameTh, firstNameEn, lastNameEn } = bankAcount
  

  return (
    <div className="bank-account-item-card" >
      <Card title={<h2 className="title-highlight">{intl.formatMessage({ id: `branchPage.atAccount` }, { number: index })}</h2>} extra={<Flex gap="12px"><Button className="button-main-line" onClick={() => onEdit(id as number)} type="primary"><FeatherIcon icon="edit-2" /></Button><Button onClick={() => onDelete(id as number)} danger><FeatherIcon icon="trash" /></Button></Flex>}>
          <div className="account-list">
              <div className="bank">
                <img src={`https://frontgame.s3.ap-southeast-1.amazonaws.com/checkslip/${bankCode}.jpg`} alt={bankCode} /> 
                <div className="title-bank">
                  <div className="text-label">{intl.formatMessage({ id: `settingPage.bankProvider` })}</div>
                  <div>{bankCodes[bankCode]}</div>
                </div>
              </div>
              <div className="account-number">
                <div className="text-label">{intl.formatMessage({ id: `settingPage.accountNumber` })}</div>
                <div> {accountNumber}</div>
              </div>
              <div className="name-account">
                <div className="text-label">{intl.formatMessage({ id: `settingPage.thaiNameAccount` })}</div>
                <div>{firstNameTh} {lastNameTh}</div>
              </div>
              <div className="name-account-eng">
                <div className="text-label">{intl.formatMessage({ id: `settingPage.engNameAccount` })}</div>
                <div>{firstNameEn} {lastNameEn}</div>
              </div>
            </div>
   
        {/* <Flex justify="space-between" align="center" ><Text>{intl.formatMessage({ id: `settingPage.bankProvider` })}</Text><Text><img style={{position: "absolute", marginLeft: "-35px", marginTop:"-3px"}} src={`https://frontgame.s3.ap-southeast-1.amazonaws.com/banks/${bankCode}.png`} width={25} alt={bankCode} /> {bankCodes[bankCode]}</Text></Flex> */}
        {/* <Flex justify="space-between" align="center" ><Text>{intl.formatMessage({ id: `settingPage.accountNumber` })}</Text><Text>{accountNumber}</Text></Flex> */}
        {/* <Flex justify="space-between" align="center" ><Text>{intl.formatMessage({ id: `settingPage.thaiNameAccount` })}</Text><Text>{firstNameTh} {lastNameTh}</Text></Flex> */}
        {/* <Flex justify="space-between" align="center" ><Text>{intl.formatMessage({ id: `settingPage.engNameAccount` })}</Text><Text>{firstNameEn} {lastNameEn}</Text></Flex> */}
      </Card>
    </div>
  );
};
