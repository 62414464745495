// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-tag-card {
  border: none;
  padding: 0px !important;
}
.setting-tag-card .ant-card-body {
  padding: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/Branch/SettingTag/SettingTag.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,uBAAA;AAAF;AACI;EACE,uBAAA;AACN","sourcesContent":["\n.setting-tag-card {\n  border: none;\n  padding: 0px !important;\n    .ant-card-body {\n      padding: 0px !important;\n    }\n}\n\n.setting-tag-card > .ant-card-body{\n  // padding: 12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
