import { UserShop } from "shared/dist";
import { api } from "./base";
import { TableParams } from "interfaces/TableParams";

export const USER_SHOP = "/user-shop";
export const USER_SHOP_DETAIL = `${USER_SHOP}/detail/:id`;
export const USER_SHOP_DETAIL_UPDATE_URL = `${USER_SHOP}/update/:shopId`;

export async function getUserShopDetail(id: string) {
  const response = await api.get(USER_SHOP_DETAIL.replace(":id", id))
  return response.data
}

export async function updateShopName(shopId: string, data: Partial<UserShop>) {
  const response = await api.put(USER_SHOP_DETAIL_UPDATE_URL.replace(":shopId", shopId), data);
  return response.data;
}

export async function getShopListWithPaging(  tableParams : TableParams) {
  const response = await api.post(USER_SHOP, {tableParams })
  return response.data
}