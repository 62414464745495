import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Spin, Grid, Dropdown, Space, Skeleton, message } from "antd";
import { useParams, Link, useSearchParams, useNavigate } from 'react-router-dom';
import "./Branch.scss"
import type { TabsProps } from 'antd';
import IntlMessages from "components/IntlMessages";
import { SettingFilled, BranchesOutlined, FileTextOutlined, TeamOutlined, DownOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';
import { HeaderNavigate } from "components/HeaderNavigate";
import { UserBranch } from "shared/dist";
import { getUserBranchDetail, updateUserBranch } from "api/userBranchService";
import { BranchOverviewTab } from "./BranchOverviewTab";
import { TransactionTab } from "./TransactionTab";
import { CustomerTab } from "./CustomerTab";
import { SettingTag } from "./SettingTag";
import { useIntl } from "react-intl";
import FeatherIcon from "feather-icons-react";

export const Branch: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const { shopId, branchId } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab')

  const [loading, setLoading] = useState<boolean>(false);
  const [branch, setBranch] = useState<UserBranch | undefined>(undefined);
  const [defaultActiveKey, setDefaultActiveKey] = useState<string>("branchOverview");
  const [messageApi, contextHolder] = message.useMessage();

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const tabSize = breakpoints.xs ? "small" : "large";
  const tabType = breakpoints.xs ? undefined : "line";
  
  useEffect(() => {
    if (branchId) {
      fetchtUserBranch(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (tab && ["branchOverview", "transaction", "customer", "settings"].includes(tab)) {
      setDefaultActiveKey(tab);
    }
  }, [branchId]);

  const fetchtUserBranch = async (id: string) => {
    setLoading(true);
    try {
      const response = await getUserBranchDetail(id)
      // console.log(response)
      setBranch(response)
    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
      if(shopId){
        navigate(`/shop/${shopId}`)
        return
      }
      navigate(`/home`)
    } finally {
      setLoading(false);
    }
  };

  if (!branchId || !branch) {
    return <Skeleton active />;
  }

  const settingItems: MenuProps['items'] = [
    {
      label: <Link to="?tab=settings"><IntlMessages id="branchPage.settingRegular" /></Link>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Link to="?tab=settings"><IntlMessages id="branchPage.settingSystem" /></Link>,
      key: '3',
    },
  ];

  const onSettingMenuClick = () => {
    console.log(`onSettingMenuClick`)
  }

  const handelBranchChange = async (values: Partial<UserBranch>) => {
    // console.log(`handelBranchNameChange: `, values)
    const key = 'updataBranchName'
    setLoading(true);
    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });

    try {
      const result = await updateUserBranch(branchId, values);
      await fetchtUserBranch(branchId);

      if(result.status !== 'suscess'){
        throw new Error(`Internal server error!`)
      }

      await fetchtUserBranch(branchId);
      setTimeout(() => {
        messageApi.open({
          key,
          type: 'success',
          content: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `branchPage.entity` }) }),
          duration: 2,
        });
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error("Error: ", err)
      setTimeout(() => {
        messageApi.open({
          key,
          type: 'error',
          content: intl.formatMessage({ id: `message.somethingWasWrong` }),
          duration: 2,
        });
      }, 1000);
      setLoading(false);
    }
  }

  const items: TabsProps['items'] = [
    {
      key: 'branchOverview',
      label: breakpoints.md ? <><FeatherIcon icon="git-branch" /><IntlMessages id="branchPage.branchOverview" /></> : <IntlMessages id="branchPage.branchOverview" />,
      children: <BranchOverviewTab branch={branch}/>,
    },
    {
      key: 'transaction',
      label: breakpoints.md ? <><FeatherIcon icon="repeat" /><IntlMessages id="branchPage.transaction" /></> : <IntlMessages id="branchPage.transaction" />,
      children: <TransactionTab branch={branch}/>,
    },
    {
      key: 'customer',
      label: breakpoints.md ? <><FeatherIcon icon="user" /><IntlMessages id="branchPage.customer" /></> : <IntlMessages id="branchPage.customer" />,
      children: <CustomerTab branch={branch}/>,
    },
    {
      key: 'settings',
      label: breakpoints.md ? <><FeatherIcon icon="settings" /><IntlMessages id="branchPage.setting" /></> : <IntlMessages id="branchPage.setting" />,
      children: <SettingTag branch={branch} onBranchChange={handelBranchChange} />,
    },
  ];

  const handelTabChange = async (activeKey: string) => {
    navigate(`?tab=${activeKey}`)
  }

  return (
    <div className="branch-main-page">
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <HeaderNavigate
              shopName={branch.userShop.shopName}
              shopId={branch.userShop.id}
              branchId={branch.id}
              branchs={
                branch.userShop.userBranchs.map(ub => ({
                  id: ub.id as number,
                  name: ub.branchName,
                  isMasterBranch: ub.isMasterBranch
                }))
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              className="tabs-section"
              defaultActiveKey={defaultActiveKey}
              onChange={handelTabChange}
              type={tabType}
              size={tabSize}
              items={items}
            />
          </Col>
        </Row>
      </Spin>
      {contextHolder}
    </div>
  );
};
