import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WalletOutlined
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { Layout, Menu, Divider, Button, Dropdown, Space, Grid, Modal } from "antd";
import { Route, Routes, Link } from "react-router-dom";
import { NotFound } from "pages/components/NotFound";
import { Profile } from "pages/components/Profile";
import { useUserAccount } from "api/useUserAccount";
import { logout, getLocale, setLocale, getMenuSelected, setMenuSelected } from "api/auth";
import { Products } from "pages/components/Products";
import { Settings } from "pages/components/Settings";
import { Shop } from "pages/components/Shop"
import { Branch } from "pages/components/Branch"
import LangSwitch from "pages/components/LangSwitch";
import IntlMessages from "components/IntlMessages";
import LogoIn from "assets/img/logo-in.png";

// admin page
import { CreditPackage, CreditPackageForm } from "pages/components/Admin/Credit";
import { UserManage, UserManageForm } from "pages/components/Admin/UserManage";
import { Homepage } from "pages/components/Home";
import { Wallet } from "pages/components/Wallet";
import { getUserWallet } from "api/userWalletService";
import { formatNumberCurrency } from "utils/number";

const { Header, Content, Footer, Sider } = Layout;

export const Template: React.FC = () => {
  const { account } = useUserAccount();
  const [collapsed, setCollapsed] = useState(false);
  const [isShowModel, setIsShowModel] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [selectedLocal, setSelectedLocal] = useState(getLocale());
  const [wallet, setWallet] = useState(0);
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  const fecthWalletAmount = async () => {
    try {
      const response = await getUserWallet()
      setWallet(response.credit);
    } catch (error) {
      console.error("Error fetching user wallet:", error);
    }
  }

  useEffect(() => {
    if (account) {
      fecthWalletAmount()
    }
  }, [account])


  const handleMenuClick = async (value: { key: string }) => {
    if (value.key === "logout") {
      await logout();
    }
  };

  const onLangChange = (loc: string) => {
    setLocale(loc);
    setSelectedLocal(loc);
    window.location.reload();
  };

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const isShowSider = breakpoints.xs ? false : true;

  const MenuContent = () => (
    <Menu
      theme={breakpoints.xs ? undefined : "dark"}
      mode="inline"
      defaultSelectedKeys={[getMenuSelected()]}
      onClick={handleMenuClick}
      defaultOpenKeys={breakpoints.xs ? ["admin"] : undefined}
    >
      <Menu.Item key="home">
        <Link to="/home" onClick={() => setMenuSelected("home")}>
          <FeatherIcon icon="home" />
          <span>
            <IntlMessages id="menu.home" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="product">
        <Link to="/products" onClick={() => setMenuSelected("product")}>
          <FeatherIcon icon="shopping-cart" />
          <span>
            <IntlMessages id="menu.product" />
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="wallet">
        <Link to="/wallet" onClick={() => setMenuSelected("wallet")}>
          <FeatherIcon icon="credit-card" />
          <span>
            <IntlMessages id="menu.wallet" />
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="profile">
        <Link to="/profile" onClick={() => setMenuSelected("profile")}>
          <FeatherIcon icon="user" />
          <span>
            <IntlMessages id="menu.profile" />
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="contact">
        <Link to="https://line.me/ti/p/xxxx" target="_blank">
          <FeatherIcon icon="message-circle" />
          <span>
            <IntlMessages id="menu.contact" />
          </span>
        </Link>
      </Menu.Item>

      {/* <Menu.Item key="settings" style={{ marginTop: '24px' }}>
            <Link to="/Settings" onClick={() => setMenuSelected("settings")}>
              <UserOutlined />
              <span><IntlMessages id="menu.settings" /></span>
            </Link>
          </Menu.Item> */}

      <Menu.SubMenu title="Admin" style={{ display: 'none' }} key="admin" icon={<FeatherIcon icon="users" />}>
        <Menu.Item key="package">
          <Link to="/admin/package" onClick={() => setMenuSelected("CreditPackage")}>
            {/* <FeatherIcon icon="circle" /> */}
            <span>Package Manage</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="user">
          <Link to="/admin/user-manage" onClick={() => setMenuSelected("user")}>
            {/* <FeatherIcon icon="circle" /> */}
            <span>User Manage</span>
          </Link>
        </Menu.Item>
      </Menu.SubMenu>

      {/* <Menu.Item key="logout">
      <FeatherIcon icon="log-out" />
        <span>
          <IntlMessages id="menu.logout" />
        </span>
      </Menu.Item> */}
    </Menu>

  );

  return (
    <Layout>
      {isShowSider && (
        <Sider
          className="sider-check-slip"
          breakpoint="lg"
          collapsedWidth="0"
          width="265"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="logo-in">
            <img src={LogoIn} />
          </div>
          <MenuContent />
        </Sider>
      )}
      <Layout>
        <Header
          className="header-check-slip"
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => (breakpoints.xs ? setIsShowModel(!isShowModel) : setCollapsed(!collapsed))}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <Space size="large" align="start">
            <LangSwitch value={selectedLocal} onChange={onLangChange} />
            <Divider type="vertical" />
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="logout">
                    <FeatherIcon icon="log-out" size={14} />
                    <span style={{ paddingLeft: "8px" }}><IntlMessages id="menu.logout" /></span>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
              onVisibleChange={() => setSelectedAccount(!selectedAccount)}
            >
              <div className="logout-style">
                <WalletOutlined />
                <span className="user-text" style={{ paddingLeft: "8px", paddingRight: '4px' }}>
                  {formatNumberCurrency(wallet, 2, 2)}
                </span>
                {selectedAccount ? <FeatherIcon icon="chevron-down" /> : <FeatherIcon icon="chevron-up" />}
              </div>
            </Dropdown>
          </Space>
        </Header>
        <Content
          className="main-content"
        // style={{
        //   margin: 10,
        //   padding: 12,
        //   background: "#fff",
        //   minHeight: 280,
        //   boxShadow:
        //     "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        //   borderRadius: 5,
        //   overflow: "auto",
        //   overflowX: "hidden",
        // }}
        >
          <Routes>
            <Route path="*" Component={NotFound} />
            <Route path="/" Component={Homepage} />
            <Route path="/home" Component={Homepage} />
            <Route path="/profile" Component={Profile} />
            <Route path="/products" Component={Products} />
            <Route path="/Settings" Component={Settings} />
            <Route path="/wallet" Component={Wallet} />
            <Route path="/shop/:id" Component={Shop} />
            <Route path="/shop/:shopId/branch/:branchId" Component={Branch} />

            {/*  Admin Page */}
            <Route path="/admin/package" Component={CreditPackage} />
            <Route path="/admin/package/form" Component={CreditPackageForm} />
            <Route path="/admin/package/form/:id" Component={CreditPackageForm} />
            <Route path="/admin/user-manage" Component={UserManage} />
            <Route path="/admin/user-manage/form" Component={UserManageForm} />
            <Route path="/admin/user-manage/form/:id" Component={UserManageForm} />
          </Routes>
        </Content>
        <Footer
          style={{
            textAlign: "center"
          }}
        >
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>
      <Modal visible={isShowModel} footer={null} onCancel={() => setIsShowModel(!isShowModel)} title="Line Check slip">
        <MenuContent />
      </Modal>
    </Layout>
  );
};
