import { BankAccount } from "shared/dist";
import { api } from "./base";

export const USER_BRANCH_BANK_ACCOUNR = "/user-branch-bank-account";
export const USER_BRANCH_BANK_ACCOUNT_LIST_URL = `${USER_BRANCH_BANK_ACCOUNR}/list/:userBrabchId`;
export const USER_BRANCH_BANK_ACCOUNT_CREATE_URL = `${USER_BRANCH_BANK_ACCOUNR}/create`;
export const USER_BRANCH_BANK_ACCOUNT_UPDATE_URL = `${USER_BRANCH_BANK_ACCOUNR}/update/:id`;
export const USER_BRANCH_BANK_ACCOUNT_DELETE_URL = `${USER_BRANCH_BANK_ACCOUNR}/delete/:id`;

export async function getUserBranchBankAccounts(userBrabchId: string | number) {
  const response = await api.get(USER_BRANCH_BANK_ACCOUNT_LIST_URL.replace(":userBrabchId", `${userBrabchId}`));
  return response.data;
}

export async function createUserBranchBankAccount(data: BankAccount) {
  const response = await api.post(USER_BRANCH_BANK_ACCOUNT_CREATE_URL, data );
  return response.data;
}

export async function updateUserBranchBankAccount(id: string | number, data: Partial<BankAccount>) {
  const response = await api.put(USER_BRANCH_BANK_ACCOUNT_UPDATE_URL.replace(":id", `${id}`), data );
  return response.data;
}

export async function deleteUserBranchBankAccount(id: string | number) {
  const response = await api.delete(USER_BRANCH_BANK_ACCOUNT_DELETE_URL.replace(":id", `${id}`));
  return response.data;
}