import React, { useState, useEffect } from "react";
import "./SystemSection.scss"
import { Card, Flex, Skeleton, Typography, notification } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { ConnectionForm } from "./ConnectionForm";
import { GroupForm } from "./GroupForm";
import { NotifyForm } from "./NotifyForm";
import { FlexResponseForm } from "./FlexResponseForm";
import { SystenVerifyForm } from "./SystenVerifyForm";
import { BankAccountForm } from "./BankAccountForm";
import { UserBranch, BankAccount, LINEGroupType } from "shared/dist";
import { getUserBranchBankAccounts, createUserBranchBankAccount, updateUserBranchBankAccount, deleteUserBranchBankAccount } from "api/userBranchBankAccountService";
import type { NotificationArgsProps } from 'antd';
import FeatherIcon from "feather-icons-react";

interface SystemSectionProps {
  branch: UserBranch
  onBranchChange: (values: Partial<UserBranch>) => Promise<void>
}

type NotificationPlacement = NotificationArgsProps['placement'];
const Context = React.createContext({ name: 'Default' });

export const SystemSection: React.FC<SystemSectionProps> = ({ branch, onBranchChange }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [userBranchBankAcounts, setUserBranchBankAcounts] = useState([]);
  const [lineGroupType, setLineGroupType] = useState(branch.lineGroupType);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (branch?.id) {
      fetchtUserBranchBankAccount(branch.id)
    }
  }, [branch?.id])

  const fetchtUserBranchBankAccount = async (id: number) => {
    setLoading(true);
    try {
      const response = await getUserBranchBankAccounts(id);
      // console.log(response.data)
      setUserBranchBankAcounts(response.data)
    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!branch?.id || loading) {
    return <Skeleton active />
  }

  const openNotification = (success: boolean) => {
    if (success) {
      api.success({
        message: intl.formatMessage({ id: `common.success` }),
        description: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `bankAccountPage.entity` }) }),
        placement: 'topRight',
      });
      return
    }

    api.error({
      message: intl.formatMessage({ id: `common.failed` }),
      description: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `bankAccountPage.entity` }) }),
      placement: 'topRight',
    });
  };

  const handleCreateBankAccount = async (data: BankAccount) => {
    try {
      await createUserBranchBankAccount(data);
      openNotification(true);
      setTimeout(() => {
        if (branch?.id) {
          fetchtUserBranchBankAccount(branch.id)
        }
      }, 2000);
    } catch (error) {
      openNotification(false);
      throw error;
    }
  };

  const handleUpdateBankAccount = async (id: number, data: BankAccount) => {
    try {
      await updateUserBranchBankAccount(id, data);
      openNotification(true);
      setTimeout(() => {
        if (branch?.id) {
          fetchtUserBranchBankAccount(branch.id)
        }
      }, 2000);
    } catch (error) {
      openNotification(false);
      throw error;
    }
  };

  const handleDeleteBankAccount = async (id: number) => {
    try {
      await deleteUserBranchBankAccount(id);
      openNotification(true);
      setTimeout(() => {
        if (branch?.id) {
          fetchtUserBranchBankAccount(branch.id)
        }
      }, 2000);
    } catch (error) {
      openNotification(false);
      throw error;
    }
  };

  const handleLineGroupTypeChange = (value: string) => {
    setLineGroupType(value as LINEGroupType);
  };

  return (
    <div className="system-section">
      <Flex className="how-to-setup" justify="center" align="center">
        <h3>
          <FeatherIcon icon="youtube" /> {intl.formatMessage({ id: `settingPage.basicSetting` })}
        </h3>
      </Flex>

      <ConnectionForm  branch={branch} onSubmit={onBranchChange} branchLineGroupType={branch.lineGroupType} branchReference={branch.reference} onLineGroupTypeChange={handleLineGroupTypeChange} />

      {lineGroupType === LINEGroupType.lineGroup && <GroupForm groupId={branch?.lineGroupId} onSubmit={onBranchChange} />}

      <NotifyForm onSubmit={onBranchChange} userBranch={branch} />

      <FlexResponseForm onSubmit={onBranchChange} userBranch={branch} />

      <SystenVerifyForm onSubmit={onBranchChange} userBranch={branch} />

      <BankAccountForm userBranchId={branch.id} bankAcounts={userBranchBankAcounts} onCreate={handleCreateBankAccount} onEdit={handleUpdateBankAccount} onDelete={handleDeleteBankAccount} />
      {contextHolder}
    </div>
  );
};
