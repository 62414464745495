import React, { useState } from "react";
import { Card, Modal, Typography , Divider } from "antd";
import "./BranchCard.scss";
import IntlMessages from "components/IntlMessages";
import cx from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";
import { formatNumberCurrency } from "utils/number";
import FeatherIcon from "feather-icons-react";

interface BranchCardProps {
  shopName: string
  branchId?: number
  branchName: string
  isMasterBranch: boolean
  allUsed: number
  totalAmount: number
  icon?: any,
  onDeleteBranch: (branchId : number) => Promise<void>,
}

export const BranchCard: React.FC<BranchCardProps> = ({
  shopName,
  branchId,
  branchName,
  isMasterBranch,
  allUsed,
  totalAmount,
  icon,
  onDeleteBranch
}) => {
  const intl = useIntl();
  const { Text } = Typography;
  const navigate = useNavigate();
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handelOk = async () => {
    setConfirmLoading(true);
    setIsOpenModel(false);
    await onDeleteBranch(branchId as number);
  }

  const handelCancel = () => {
    setConfirmLoading(false);
    setIsOpenModel(false);
  }

  return (
    <Card className="branch-card">
      <div className="header">
        <div className="nameSection" onClick={() => navigate(`branch/${branchId}`)}>
          <div className={cx("icon", isMasterBranch ? "bg-green" : "bg-blue")} >{icon ?? <><FeatherIcon icon="home" /> </>}</div>
          <div className="name">
            <h2 className="shopName title-highlight">{shopName}</h2>
            <div className="branchName">{branchName}&nbsp;{isMasterBranch && intl.formatMessage({ id: 'shopPage.mainBranch'}) }</div>
          </div>
        </div>
        <div className="action">
          <div onClick={() => navigate(`branch/${branchId}`)}><FeatherIcon icon="settings" /> </div>
          {!isMasterBranch && 
          <>
           <Divider type="vertical" />
           <div onClick={() => setIsOpenModel(true)}><FeatherIcon icon="trash"  /></div>
          </>
          }
        
        </div>
      </div>
      <div className="content">
        <div className="section text-left">
          <div className="title">{<IntlMessages id="shopPage.useAll" />}</div>
          <div className="label">{formatNumberCurrency(allUsed,0,0)}</div>
        </div>
        <div className="section text-right">
          <div className="title">{<IntlMessages id="shopPage.totalAmount" />}</div>
          <div className="label">{formatNumberCurrency(totalAmount,2,2)}</div>
        </div>
      </div>
      <Modal
        title={<Text>{intl.formatMessage({ id: `message.confirm` })}</Text>}
        open={isOpenModel}
        onOk={handelOk}
        onCancel={handelCancel}
        confirmLoading={confirmLoading}
      >
        {intl.formatMessage({ id: `message.confirmDeleterEntity` },  { entity: intl.formatMessage({ id: 'branchPage.entity'}) })}
      </Modal>
    </Card>
  );
};
