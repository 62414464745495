import React, { useEffect } from "react";
import "./SystenVerifyForm.scss";
import { Form, Button, Flex, Typography, Card, Grid, Radio, InputNumber } from "antd";
import { SettingFilled } from "@ant-design/icons";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { UserBranch } from "shared/dist";
import FeatherIcon from "feather-icons-react";

interface SystenVerifyFormProps {
  onSubmit: (values: Partial<UserBranch>) => Promise<void>
  userBranch: UserBranch
}

export const SystenVerifyForm: React.FC<SystenVerifyFormProps> = ({ onSubmit, userBranch }) => {
  const { Text } = Typography;
  const onFinish = (values: Partial<UserBranch>) => {
    // console.log("Received values of form: ", values);
    onSubmit(values);
  };
  const intl = useIntl();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const fontSize = breakpoints.xs ? "12px" : "15px";

  // const [amount, setAmount] = useState(0);

  // const handleAgeChange = (value: number) => {
  //   // console.log("value = ", value)
  //   setAmount(value)
  // };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ detectDuplicateSlip: userBranch.detectDuplicateSlip, detectMinAmount: userBranch.detectMinAmount, hidePayerAccount: userBranch.hidePayerAccount, hidePayeeAccount: userBranch.hidePayeeAccount });
  }, [userBranch.validSlip, userBranch.detectMinAmount, userBranch.hidePayerAccount, userBranch.hidePayeeAccount])


  return (
    <div className="systen-verify-form" >
      <Card title={<><FeatherIcon icon="settings" /> <IntlMessages id="settingPage.settingForCheckSystem" /></>}>
        <Form
          name="notify-form"
          onFinish={onFinish}
          initialValues={{ detectDuplicateSlip: 1, detectMinAmount: 0, hidePayerAccount: 1, hidePayeeAccount: 1 }}
          form={form}
        >

        <div className="menu-list-systen-verify">
            <h2>
              {intl.formatMessage({ id: `settingPage.openForCheckSlipDuplicate` })}
            </h2>
            <Form.Item name="detectDuplicateSlip" style={{ marginBottom: 0 }}>
              <Radio.Group defaultValue={1} buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                <Radio.Button value={1}>{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                <Radio.Button value={0}>{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>


          {/* <Form.Item name="b" style={{ marginBottom: 0 }}>*/}
          {/* 
            <Flex
              vertical={breakpoints.xs ? true : false}
              justify="space-between"
              align={breakpoints.xs ? "" : "center"}
              style={{ marginRight: "1rem", marginLeft: "1rem", marginBottom: "1rem" }}
            >
              <Typography.Title level={5} style={{ margin: "0", fontSize: fontSize }}>
                {intl.formatMessage({ id: `settingPage.openForCheckGroup` })}
              </Typography.Title>
              <Radio.Group defaultValue={true} buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                <Radio.Button value={true}>{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                <Radio.Button value={false}>{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
              </Radio.Group>
            </Flex>
            */}
          {/*</Form.Item>*/}


          <div className="menu-list-systen-verify-form">
            <Text type="secondary"> {intl.formatMessage({ id: `settingPage.wornningYourAmountIsLow` })}</Text>
            <Form.Item name="detectMinAmount" style={{ marginBottom: 0 }}>
              <InputNumber size="large" addonAfter="บาท" placeholder="cccccc" style={{ width: '100%' }} />
            </Form.Item>
          </div>


          <div className="menu-list-systen-verify">
            <h2>
              {intl.formatMessage({ id: `settingPage.hiddenAccountForTranfer` })}
            </h2>
            <Form.Item name="hidePayerAccount" style={{ marginBottom: 0 }}>
              <Radio.Group defaultValue={1} buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                <Radio.Button value={1} >{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                <Radio.Button value={0}>{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="menu-list-systen-verify">
            <h2>
              {intl.formatMessage({ id: `settingPage.hiddenAccountForTranferDestination` })}
            </h2>
            <Form.Item name="hidePayeeAccount" style={{ marginBottom: 0 }}>
              <Radio.Group defaultValue={1} buttonStyle="solid" style={{ margin: "0", fontSize: fontSize }}>
                <Radio.Button value={1}>{intl.formatMessage({ id: `settingPage.open` })}</Radio.Button>
                <Radio.Button value={0}>{intl.formatMessage({ id: `settingPage.close` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

            <Button size="large" className="button-main" type="primary" htmlType="submit">
              {intl.formatMessage({ id: `settingPage.saveData` })}
            </Button>
    
        </Form>
      </Card>
    </div>
  );
};
