import React, { useState } from "react";
import { Spin } from "antd";
import { LoginForm } from "./LoginForm";
import { login } from "api/auth";
import logoCheckSlip from "assets/img/logo-in.png";
import { useIntl } from "react-intl";
import { useNotification } from "components/Notification";
import IntlMessages from "components/IntlMessages";

export const Login: React.FC = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const { notification, contextNotification } = useNotification();

  const handelSubmitFrom = async (data: { username: string; password: string; remember: boolean }) => {
    setLoading(true);
    try {
      await login(data.username, data.password);
      notification.success({
        message: <IntlMessages id="success" />,
        description: intl.formatMessage({ id: `message.signInSuccess` }),
        placement: 'topRight',
      });
      await setTimeout(async () => {
        setLoading(false);
        window.location.href = "/";
      }, 1000);
    } catch (err) {
      notification.error({
        message: <IntlMessages id="failed" />,
        description: intl.formatMessage({ id: `message.signInFailed` }),
        placement: 'topRight',
      });
      console.error(`err: `, err);
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <div className="login-page">
        <div className="container-check-slip">
          <div className="login-warp h-100">
          <div className="content-carousel">
              <div>
                <img src={logoCheckSlip} />
                <h1>
                  <div>บริการตรวจสอบสลิป</div>
                  <div>ที่รวดเร็วและแม่นยำที่สุด</div>
                </h1>
              </div>
            </div>
            <div className="content-login">
              <LoginForm onSubmit={handelSubmitFrom} />
            </div>
          </div>
        </div>
        {contextNotification}
      </div>
    </Spin>
  );
};
