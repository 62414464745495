import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { api } from 'api/instances'
import { PATHS } from "api/paths";
import { useParams, useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs-react'
type CreditPackageParams = {
  id?: string;
}

export const UserManageForm: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<CreditPackageParams>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      fetchUserAccount();
    }
  }, [id]);

  const fetchUserAccount = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${PATHS.USER_ACCOUNT_PROFILE}/${id}`);
      // console.log(response)
      const creditPackage = response.data;
      form.setFieldsValue(creditPackage);
    } catch (error) {
      console.error('Failed to fetch user:', error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      if (values.password) {
        const hashedPassword = await bcrypt.hash(values.password, 10);
        values.password = hashedPassword;
      }
      if (isEditMode) {
        await api.post(`${PATHS.USER_ACCOUNT_UPDATE}/${id}`, values);
        message.success('CreditPackage updated successfully');
        navigate('/admin/user-manage')
      } else {
        await api.post(PATHS.USER_ACCOUNT_ADD, values);
        message.success('CreditPackage added successfully');
        navigate('/admin/user-manage')
      }
    } catch (error) {
      console.error('Failed to save user:', error);
      message.error('Failed to save user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <h1>{id ? 'Update' : 'Add'} User Account</h1>
    <Form form={form} layout="vertical" onFinish={onFinish}>
    <Form.Item
        name="username"
        label="Username"
        rules={[{ required: true, message: 'Please enter the username' }]}
      >
        <Input disabled={isEditMode} />
      </Form.Item>
      {!isEditMode && (
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please enter the password' }]}
        >
          <Input.Password />
        </Form.Item>
      )}
      {isEditMode && (
        <Form.Item name="password" label="New Password">
          <Input.Password />
        </Form.Item>
      )}
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true, message: 'Please enter the first name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter the last name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: 'Please enter the phone number' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="contact" label="Contact">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
        {isEditMode ? 'Update User' : 'Create User'}
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};
