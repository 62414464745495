import React, { useState, useEffect } from "react";
import { Row, Col, ColProps, RowProps, Spin } from "antd";
import { Header } from "../Header";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { ProductsCardItems } from "../ProductsCardItems";
import { useIntl } from "react-intl";
import { CreditPackage } from "interfaces/CreditPackage";
import { getCreditpackage } from "api/creditPackage";
import { getUserWallet } from "api/userWalletService";

export const ProductFrom: React.FC = () => {
  const intl = useIntl();
  const [data, setData] = useState<CreditPackage[]>();
  const [loading, setLoading] = useState(false);
  const [disableFreePackageFlag, setDisableFreePackage] = useState(false)
  const [userWallet, setUserWallet]  = useState(0)

  const requestObj = {
    current: 1,
    pageSize: 100,
    activeStatus: 1,
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getCreditpackage(requestObj)
      setData(response.data);
      // console.log(response)
      // Set free package
      setDisableFreePackage(response.data.isFreePackage)
      const userWalletResponse = await getUserWallet()
      if(userWalletResponse){
        // console.log(userWalletResponse.credit)
        setUserWallet(userWalletResponse.credit)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (id: number) => {
    alert("select package" + id);
  };

  const colStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 8 },
    xxl: { span: 6 },
  };
  const rowStyle: RowProps = { gutter: { xs: 8, sm: 16, md: 24, lg: 32 } };
  return (
    <Spin spinning={loading}>
      <div className="products-page">
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <Header
              title={intl.formatMessage({ id: `productPage.header` })}
              subtitle={intl.formatMessage({ id: `productPage.subtitle` })}
              icon={<ShoppingCartOutlined />}
            />
          </Col>
        </Row>

        <Row {...rowStyle} gutter={[16, 0]}>
          {data?.map((item) => (
            <Col {...colStyle} key={item.id} >
              <ProductsCardItems creditPackage={item} onSelect={handleSelect} isDisableFreePackage={disableFreePackageFlag} useWallet={userWallet}/>
            </Col>
          ))}
        </Row>
      </div>
    </Spin>
  );
};