export const PATHS = {
  USER_ACCOUNT_PROFILE: "/user-account",
  USER_ACCOUNT_DETAIL: "/user-account/:id",
  USER_ACCOUNT_ADD: "/user-account/add",
  USER_ACCOUNT_UPDATE: "/user-account/update",

  CREDIT_PACKAGE: "/package",
  CREDIT_PACKAGE_ADD: "/package/add",
  CREDIT_PACKAGE_UPDATE: "/package/update",

  USER_PACKAGE: "/user-package",

  SIGNIN: "/auth/sign-in",
  SIGNUP: "/auth/sign-up"
}