import { Button, Flex, Grid } from "antd";
import React, { useEffect, useState } from 'react';
import { GetProp, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType, TableProps } from 'antd';
import { api } from 'api/instances'
import { PATHS } from "api/paths";
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  id: number;
  name: string;
  creditAmount: number;
  price: number;
  creditPerday: number;
  expirationDays: number;
  maxConnect: number;
  description: string;
  imgUrl: string;
  isActive: boolean;
  startAt: string;
  endAt: string;
  createAt: string;
  updateAt: string;
}
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const data: DataType[] = [];

export const CreditPackage: React.FC = () => {
  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const navigate = useNavigate();
  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'description',
    },
    {
      title: 'Credit',
      dataIndex: 'creditAmount',
      sorter: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'สถานะ',
      dataIndex: 'isActive',
      sorter: true,
      render: (isActive) => (
        <span>{isActive ? 'ใช้งาน' : 'ปิดใช้งาน'}</span>
      ),
      width: '20%',
    },
  
    {
      title: 'Action',
      key: 'action',
      sorter: true,
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`form/${record.id}`)}>
          Edit
        </Button>
      ),
    },
  ];

  const requestObj = {
    current: 1,
    pageSize: 20
  };


  const fetchData = () => {
    setLoading(true);
    try {
      api.post(PATHS.CREDIT_PACKAGE, { requestObj })
        .then(res => {
          // console.log(res)
          setData(res.data.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.meta.total,
              showSizeChanger: true,
              pageSizeOptions: [20, 50, 100]  
            },
          });
        })
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);


  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  return (
    <>
      <div><h1>Credit Package List</h1></div>
      <Flex gap="small" >
        <Button href="/admin/package/form" type="primary">เพิ่มแพคเกจ</Button>
      </Flex>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};
