import React, { useState, useEffect } from "react";
import "./ConnectionForm.scss";
import { Form, Button, Row, Col, Flex, Card, Radio, Grid } from "antd";
import { MessageOutlined, TeamOutlined } from "@ant-design/icons";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { ApiOutlined } from "@ant-design/icons";
import { LINEGroupType, UserBranch } from "shared/dist";
import { LineOAFrom } from "./LineOAFrom";
import { LineGroupFrom } from "./LineGroupFrom";
import FeatherIcon from "feather-icons-react";

interface ConnectionFormProps {
  branch: UserBranch
  onSubmit: (values: Partial<UserBranch>) => Promise<void>
  branchLineGroupType: LINEGroupType;
  branchReference: string;
  onLineGroupTypeChange: (value: string) => void
}

export const ConnectionForm: React.FC<ConnectionFormProps> = ({ branch, onSubmit, branchLineGroupType, branchReference, onLineGroupTypeChange }) => {
  const [lineGroupType, setLineGroupType] = useState<LINEGroupType>(branchLineGroupType)
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const fontSize = breakpoints.xs ? "12px" : "16px";

  useEffect(() => {
    form.setFieldsValue({ lineChannelSecret: branch.lineChannelSecret, lineChannelToken:branch.lineChannelToken, lineGroupType: branch.lineGroupType });
  }, [branch.lineChannelSecret, branch.lineChannelToken, branch.lineGroupType])

  const onFinish = (values: any) => {
    // console.log("Received values of form: ", values);
    onSubmit(values);
  };
  const intl = useIntl();
  const handelLineGroupTypeChange = (value: LINEGroupType) => {
    setLineGroupType(value);
    onLineGroupTypeChange(value);
  }

  return (
    <div className="connection-form" >
      <Card title={<><FeatherIcon icon="link-2" /><IntlMessages id="branchPage.setUpSystemConnection" /></>}>
      <div className="content-connection-form">
        <Form
          name="connection-form"
          form={form}
          onFinish={onFinish}
          initialValues={{
            lineChannelSecret: branch.lineChannelSecret, 
            lineChannelToken: branch.lineChannelToken,
            lineChannelTokenLongLive: branch.lineChannelTokenLongLive,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="lineGroupType" className="radio-group-checkslip">
                <Radio.Group defaultValue={branch.lineGroupType==="OA"? LINEGroupType.lineOA : LINEGroupType.lineGroup} buttonStyle="solid" size="large" onChange={e => handelLineGroupTypeChange(e.target.value)} >
                  <Radio.Button value={LINEGroupType.lineGroup}><div className="radio-button-checkslip"><FeatherIcon icon="users" /> Line Group</div></Radio.Button>
                  <Radio.Button value={LINEGroupType.lineOA}><div className="radio-button-checkslip" ><FeatherIcon icon="message-circle" />{breakpoints.xs ? "Line OA" : "Line OA (Webhook)"}</div></Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {lineGroupType === LINEGroupType.lineGroup && <LineGroupFrom reference={branchReference} userBranch={branch} />}
          {lineGroupType === LINEGroupType.lineOA && <LineOAFrom channelId={branch.lineChannelSecret} reference={branchReference} userbranch={branch} />}
          <div className="control-button-submit">
            <Button size="large" className="button-main" type="primary" htmlType="submit">
              {intl.formatMessage({ id: `settingPage.saveData` })}
            </Button>
          </div>
        </Form>
        </div>
      </Card>
    </div>
  );
};
