import React from "react";
import "./GroupForm.scss";
import { Form, Input, Button, Row, Col, Flex, Typography, Card } from "antd";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { TeamOutlined  } from "@ant-design/icons";
import { UserBranch } from "shared/dist";
import FeatherIcon from "feather-icons-react";

interface GroupFormProps {
  onSubmit: (values: Partial<UserBranch>) => Promise<void>,
  groupId: string
}

export const GroupForm: React.FC<GroupFormProps> = ({ onSubmit, groupId = ''}) => {
  const onFinish = (values: Partial<UserBranch>) => {
    // console.log("Received values of form: ", values);
    onSubmit(values);
  };
  const intl = useIntl();
  const { Text } = Typography;

  return (
    <div className="group-form" >
      <Card title={<><FeatherIcon icon="tool" /> <IntlMessages id="settingPage.settingGroup" /></>}>
        <Form
          name="group-form"
          onFinish={  onFinish  }         
        >
          <Row>
            <Col span={24}>
              <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
                <Text className="title">- Group ID (ระบบไลน์กลุ่ม ถ้าไม่ใช้ ห้ามใส่เด็ดขาด)</Text>
                <Text type="secondary">วิธีการเอา Group ID ให้ดึง OA ของท่านเข้ากลุ่ม แล้วพิมพ์คำสั่ง /thunder-start</Text>
                <Form.Item name="groupId" >
                <Input size="large" disabled value={groupId} />
                </Form.Item>
                {/* <Button block type="primary" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} htmlType="submit"   >
                  {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button> */}
              </Flex>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};
