import React, { useState, useEffect } from "react";
import { Card, Steps, Dropdown, Space } from "antd";
import type { StepProps, MenuProps } from "antd";
import "./HeaderNavigate.scss";
import { HomeOutlined, ShopOutlined, BranchesOutlined, LeftOutlined, DownOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import IntlMessages from "components/IntlMessages";
import FeatherIcon from "feather-icons-react";

interface HeaderNavigateProps {
  shopName: string
  shopId?: number
  branchId?: number
  branchs: {
    id: number,
    name: string,
    isMasterBranch?: boolean
  }[]
}

export const HeaderNavigate: React.FC<HeaderNavigateProps> = ({
  shopName,
  shopId,
  branchId,
  branchs
}) => {
  const navigate = useNavigate()
  
  const shopItems: MenuProps['items'] = []
  branchs?.map((item, index) => {
    if (index) {
      shopItems.push({ type: 'divider' })
    }
    shopItems.push({
      label: <Link to={`/shop/${shopId}/branch/${item.id}?tab=settings`}>{item.isMasterBranch ? <FeatherIcon icon="archive" /> : <FeatherIcon icon="git-branch" />}&nbsp;{item.name}</Link>,
      key: item.id,
    })
  })

  const stepDefaultItems: StepProps[] = [
    {
      title: <IntlMessages id="menu.home" />,
      status: 'finish',
      icon: <FeatherIcon icon="home" />,
    },
    {
      title:
        <Dropdown menu={{ items: shopItems }} trigger={['click']}>
           <Space>
              <IntlMessages id="branchPage.setting" />
              <DownOutlined className="icon-small" />
            </Space>
        </Dropdown>,
      status: 'finish',
      icon: <FeatherIcon icon="archive" />,
    },
  ]

  const [current, setCurrent] = useState<number>(1)
  const [stepItems, setsStepItems] = useState<StepProps[]>(stepDefaultItems)

  const onChange = (value: number) => {
    // console.log('onChange:', value);
    setCurrent(value);
    switch (value) {
      case 0: navigate(`/shop/${shopId}`)
    }
  };

  useEffect(() => {
    const branch = branchs?.find(i => i.id === branchId)
    if (branch) {
      setsStepItems([
        ...stepDefaultItems, 
        {
          title: branch?.name,
          status: 'finish',
          icon: <BranchesOutlined />,
        }
      ])
      setCurrent(2)
    }
  }, [branchId])

  return (
    <div className={"header-navigate"}>
      <div className="header">
        <div onClick={() => navigate(-1)} className="button-back"><FeatherIcon icon="chevron-left" /></div>
        <h1 className="title-shop title-highlight">{shopName}</h1>
        <div className="prifile"></div>
      </div>
      <div className="navigate-step-branch">
        <Steps
          items={stepItems}
          current={current}
          onChange={onChange}
          type="navigation"
        />
      </div>
    </div>
  );
};
