import React, { useEffect, useRef } from "react";
import { Card, Table } from "antd";
import type { TableColumnsType } from 'antd';
import { useIntl } from "react-intl";
import "./BillingHistoryTab.scss";
import IntlMessages from "components/IntlMessages";



export const BillingHistoryTab = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const columns: TableColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'row',
    },
    {
      title: <IntlMessages id="shopPage.type" />,
      dataIndex: 'type',
    },
    {
      title: <IntlMessages id="shopPage.amountOfMoney" />,
      dataIndex: 'amountOfMoney',
      // sorter: true,
    },
    {
      title: <IntlMessages id="shopPage.automatic" />,
      dataIndex: 'automatic',
    },
    {
      title: <IntlMessages id="common.date" />,
      dataIndex: 'date',
      // sorter: true,
    },
  ];

  useEffect(() => { 
    console.log('BillingHistoryTab useEffect');
    fetchData();
  }, []);

  const fetchData = async () => {
    // Implement your fetch logic here
    console.log('fetchData');
  };

  React.useImperativeHandle(ref, () => ({
    fetchData
  }));

  return (
    <Card className="billing-history-tab">
      <Table columns={columns} pagination={
        {
          total: 0,
          showSizeChanger: true,
          pageSizeOptions: [20, 50, 100]
        }} 
        scroll={{ x: 'calc(700px + 50%)'}}
        />
    </Card>
  );
});
