import { Card, Flex, ColProps, Col, Button, Grid, Typography, Row } from "antd";
import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
export const OverviewPage: React.FC = () => {
  const colStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xxl: { span: 12 },
  };
  const flexStyle: React.CSSProperties = {
    border: "1px",
    borderStyle: "double",
    borderColor: "gray",
    borderRadius: "5px",
  };

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const fontSize = breakpoints.xs ? "12px" : "15px";
  const { Text } = Typography;
  return (
    <Col span={24}>
      <Card
        style={{ width: "100%" }}
        title={
          <>
            <Flex justify="space-between" align="center">
              <p style={{ fontSize: fontSize }}>New Feature</p>
              <Button
                icon={<ClockCircleOutlined />}
                style={{
                  background: "none",
                  border: "none",
                }}
              />
            </Flex>
          </>
        }
      >
        <Row>
          <Col {...colStyle}>
            <Card
              className="deletePadding"
              title={
                <>
                  <Flex justify="center" align="center">
                    <Typography.Title level={5}>จำนวนลูกค้า</Typography.Title>
                  </Flex>
                </>
              }
            >
              <Flex vertical>
                <Flex justify="center">
                  <Text style={{ padding: 20 }}>0 คน</Text>
                </Flex>
                <Flex justify="center" style={{ backgroundColor: "bisque", padding: 5 }}>
                  <Text>ดาวโหลดข้อมูลลูกค้า d</Text>
                </Flex>
              </Flex>
            </Card>
          </Col>

          <Col {...colStyle}>
            <Card
              className="deletePadding"
              title={
                <>
                  <Flex justify="center" align="center">
                    <Typography.Title level={5}>จำนวนลูกค้า</Typography.Title>
                  </Flex>
                </>
              }
            >
              <Flex vertical>
                <Flex justify="center">
                  <Text style={{ padding: 20 }}>0 คน</Text>
                </Flex>
                <Flex justify="center" style={{ backgroundColor: "bisque", padding: 5 }}>
                  <Text>ดาวโหลดข้อมูลลูกค้า d</Text>
                </Flex>
              </Flex>
            </Card>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
