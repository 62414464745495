import React, { useEffect } from "react";
import "./NotifyForm.scss";
import { Form, Input, Button, Row, Col, Flex, Typography, Card } from "antd";
import { BellOutlined, ExportOutlined } from "@ant-design/icons";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { UserBranch } from "shared/dist";
import FeatherIcon from "feather-icons-react";

interface NotifyFormProps {
  onSubmit: (values: Partial<UserBranch>) => Promise<void>
  userBranch: UserBranch
}

export const NotifyForm: React.FC<NotifyFormProps> = ({ onSubmit, userBranch }) => {
  const onFinish = async (values: Partial<UserBranch>) => {
    // console.log("Received values of form: ", values);
    await onSubmit(values);
  };
  const intl = useIntl();
  const { Text, Link } = Typography;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ lineNotifyToken: userBranch.lineNotifyToken });
  }, [userBranch.lineNotifyToken])

  return (
    <div className="notify-form" >
      <Card title={<><FeatherIcon icon="bell" /><IntlMessages id="settingPage.settingForNotification" /></>}>
      <div className="content-notify-form">
        <div className="head-title">
          <h2>Line Notify(Token)</h2>
        </div>
        <div className="contect-link">
          <Link target="_blank" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=f4xH7d85LT7LZ8Izu30ddc&redirect_uri=${process.env?.REACT_APP_LINE_NITIFY_REDIRECT_URL}&scope=notify&state=${userBranch.id}`} ><span>เชื่อมต่อ Line Notify </span><FeatherIcon icon="external-link" /></Link>
          <Text type="secondary"> {intl.formatMessage({ id: `settingPage.gennerateTokenFrom` })}<Link target="_blank" href="https://notify-bot.line.me">https://notify-bot.line.me</Link></Text>
          <Form
            name="notify-form"
            onFinish={onFinish}
           form={form}
          >
          <Form.Item name="lineNotifyToken" >
            <Input size="large" />
          </Form.Item>
          <Button size="large"  className="button-main" type="primary" htmlType="submit">
            {intl.formatMessage({ id: `settingPage.saveData` })}
          </Button>
          </Form>
        </div>
      </div>
        
          
     
        
        {/* <Row>
          <Col span={24}>
            <Flex style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "0.5rem" }} vertical>
              <Text className="title">- Line Notify(Token)</Text>
              <Link target="_blank" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=f4xH7d85LT7LZ8Izu30ddc&redirect_uri=${process.env?.REACT_APP_LINE_NITIFY_REDIRECT_URL}&scope=notify&state=${userBranch.id}`} >เชื่อมต่อ Line Notify <ExportOutlined/></Link>
              <Text type="secondary"> {intl.formatMessage({ id: `settingPage.gennerateTokenFrom` })}<Link target="_blank" href="https://notify-bot.line.me">https://notify-bot.line.me</Link></Text>
              <Form
                name="notify-form"
                onFinish={onFinish}
                form={form}
              >
                <Form.Item name="lineNotifyToken" >
                  <Input size="large" />
                </Form.Item>
                <Button  className="button-main" block type="primary" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} htmlType="submit">
                  {intl.formatMessage({ id: `settingPage.saveData` })}
                </Button>
              </Form>
            </Flex>
          </Col>
        </Row> */}

      </Card>
    </div>
  );
};
