import React, { useEffect, useState } from "react";
import { Collapse, Button, Col, Row, Typography, Tabs, Grid, Badge, Space, Progress, Empty, Alert } from "antd";
import "./ShopCollapse.scss";
import { ShopStatus } from "shared/dist";
import type { ColProps, CollapseProps, TabsProps } from 'antd';
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { BranchList } from "./BranchList";
import { formatNumberCurrency } from "utils/number";
import FeatherIcon from "feather-icons-react";
import { WarningTwoTone, SyncOutlined } from "@ant-design/icons";

interface ShopList {
  shops: any | undefined
}

const colShopHeader: ColProps = {
  xs: { span: 24 },
  sm: { span: 6 },
  md: { span: 6 },
  lg: { span: 6 },
  xxl: { span: 6 },
};

const colShopHeader2nd: ColProps = {
  xs: { span: 24 },
  sm: { span: 6 },
  md: { span: 6 },
  lg: { span: 6 },
  xxl: { span: 6 },
};

const colShopHeader3Col: ColProps = {
  xs: { span: 12 },
  sm: { span: 6 },
  md: { span: 6 },
  lg: { span: 6 },
  xxl: { span: 6 },
};

const colPacketSpan: ColProps = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 6 },
  lg: { span: 6 },
  xxl: { span: 6 },
};

const { Text } = Typography;
export const ShopCollapse: React.FC<ShopList> = ({
  shops = []
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const [shopList, setShopList] = useState<CollapseProps['items']>([]);

  const isExpireDate = (expireDate: string) => {
    if (new Date(expireDate) < new Date()) {
      return true
    }
    return true
  }

  useEffect(() => {
    const newShopList: CollapseProps['items'] = []
    if (shops?.length) {
      shops.forEach((shop: any) => {
        const calPercentCredit = (shop.credit / shop.totalCredit) * 100

        const items: TabsProps['items'] = [
          {
            key: '1',
            label: <><FeatherIcon icon="git-branch" /> {intl.formatMessage({ id: `common.branch` })}</>,
            children: <BranchList branchList={shop.branchs} shopId={shop.id} />,
          },
          {
            key: '2',
            label: <><FeatherIcon icon="package" /> {intl.formatMessage({ id: `common.package` })}</>,
            children: <>
              <div className="card-package">
                <Row style={{ marginBottom: '12px' }}>
                  <Col {...colPacketSpan} className="text-left text-gray font-14">{intl.formatMessage({ id: `common.package` })}</Col>
                  <Col {...colPacketSpan} className="text-left"> <Text > {shop.creditPackageName} </Text></Col>
                </Row>
                <Row style={{ marginBottom: '12px' }}>
                  <Col {...colPacketSpan} className="text-left text-gray">{intl.formatMessage({ id: `homePage.startPackage` })}</Col>
                  <Col {...colPacketSpan} className="text-left">
                    <Text >  {new Date(shop.createdAt).toLocaleString('th-TH', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })
                    }
                    </Text>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '12px' }}>
                  <Col {...colPacketSpan} className="text-left text-gray font-14">{intl.formatMessage({ id: `homePage.endPackage` })}</Col>
                  <Col {...colPacketSpan} className="text-left">
                    <Text >  {new Date(shop.expirePackageAt).toLocaleString('th-TH', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })
                    }
                    </Text>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '12px' }}>
                  <Col {...colPacketSpan} className="text-left text-gray font-14">{intl.formatMessage({ id: `homePage.remainCheckSlipAmount` })}</Col>
                  <Col span={24} className="text-left">
                    <Progress percent={calPercentCredit} strokeColor={{ from: '#108ee9', to: '#87d068' }}
                      format={() => `${shop.credit} / ${shop.totalCredit}`}
                    />
                  </Col>
                </Row>
              </div>

            </>,
          },
        ]

        let newShop = {
          key: shop.id,
          label:
            <>
              <Row justify="space-between" className="collapse-package-details" gutter={[0, 0]}>
                <Col {...colShopHeader}  >
                  {/* <Col>
                    <ShopTwoTone twoToneColor="#303440" style={{ fontSize: '28px', padding: '3px', marginRight: '9px' }} />
                  </Col> */}
                  <Col className="name-shop">
                    <div className="icon-shop">
                      <FeatherIcon icon="archive" />
                    </div>
                    <div>
                      <h2 className="title-highlight " style={{ fontSize: '20px !important' }}>{shop.shopName}</h2>
                      {/* {shop.shopStatus === ShopStatus.active ?
                      <Text style={{ fontSize: '13px', color: '#0ac755' }} >
                        ๐  {intl.formatMessage({ id: `shopPage.active` })}
                      </Text> :
                      <Text style={{ fontSize: '13px', color: '#5aaa00' }} >
                        ๐ {intl.formatMessage({ id: `shopPage.inActive` })}
                      </Text>
                    } */}
                      <Space direction="vertical">
                        {shop.shopStatus === ShopStatus.active ?
                          <Badge status="success" text={intl.formatMessage({ id: `shopPage.active` })} />
                          :
                          <Badge status="default" text={intl.formatMessage({ id: `shopPage.inActive` })} />
                        }
                      </Space>
                    </div>

                  </Col>

                </Col>
                <Col {...colShopHeader2nd} >
                  <Text className="font-14" type="secondary">{intl.formatMessage({ id: `servicePage.expiredAt` })}</Text>
                  <br />
                  {shop.expirePackageAt !== null ? new Date(shop.expirePackageAt).toLocaleString('th-TH', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  }) : ''}
                </Col>
                <Col {...colShopHeader3Col} style={{ textAlign: breakpoints.xs ? 'right' : 'left' }}>
                  <Text className="font-14" type="secondary"> {intl.formatMessage({ id: `homePage.total` })}</Text>
                  <br />
                  {formatNumberCurrency(shop.totalAmount, 2, 2)}
                </Col>
                <Col>
                  <Button size="large" type="primary" onClick={() => navigate(`/shop/${shop.id}`)} icon={<FeatherIcon icon="pie-chart" />} className={"button-main"} >
                    <span className="text">{intl.formatMessage({ id: `homePage.overView` })}</span>
                  </Button>
                </Col>
              </Row>
              {!isExpireDate(shop.expirePackageAt) &&
                <Row>
                  <Col span={24} style={{ marginTop: '10px' }}>
                    <Alert message={<><WarningTwoTone twoToneColor="red" /> <Text style={{ color: 'red', fontWeight: '300' }}>{intl.formatMessage({ id: `shopPage.expired` })}</Text></>} type="error" action={
                      <Space >
                        <Button type="default" disabled={shop.packageId === 1}>
                          {intl.formatMessage({ id: `shopPage.renew` })}
                        </Button>
                        <Button  type="text" className="title-highlight " onClick={() => navigate(`/products?shop=${shop.id}`)}>
                          <SyncOutlined style={{ color: "red" }} /> {intl.formatMessage({ id: `shopPage.changePackage` })}
                        </Button>
                      </Space>
                    } />
                  </Col>
                </Row>
              }
            </>
          ,
          children: <Tabs defaultActiveKey="1" items={items} />
          ,
        }
        newShopList.push(newShop)
      })
      setShopList(newShopList)
    }
  }, [shops]);

  return (
    <div className="manage-package">
      {shopList?.length ? <Collapse
        className="shop-card"
        defaultActiveKey={['1']}
        items={shopList}
        expandIconPosition={'end'}
      /> : <Empty />}
    </div>
  );
};
