import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import localeTH from 'antd/lib/locale/th_TH';
import localeEN from 'antd/lib/locale/en_US';
import { SWRConfig } from "swr"
import { apiFetcher } from "api/fetchers"
import { getLocale } from "api/auth"
import { IntlProvider } from 'react-intl';
import AppLocale from 'lng-provider';

const antdLocales = {
  th: localeTH,
  en: localeEN,
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const getAppLocale = () => {
  const locale = getLocale()
  return locale === 'th' ? AppLocale[locale] : AppLocale['en']
}

root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        // TODO: Set interval for specific one only
        refreshInterval: 0,
        fetcher: apiFetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <ConfigProvider locale={getLocale() === 'th' ? antdLocales.th : antdLocales.en}
        theme={
          {
            "token": {
              "colorTextBase": "#3b3737",
              "fontSize": 16,
              "borderRadius": 8,
              "boxShadow": "gba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;",
              "boxShadowSecondary": "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;",
              "wireframe": false,
              "colorPrimary": "#3b8cff",
              "colorInfo": "#3b8cff"
            },
            "components": {
              "Menu": {
                "colorText": "rgba(128, 128, 128, 0.88)",
                "itemSelectedBg": "rgb(255, 255, 255)",
                "itemSelectedColor": "rgb(66, 66, 67)",
                "horizontalItemSelectedColor": "rgb(255, 22, 22)",
                "horizontalItemHoverColor": "rgb(255, 22, 22)",
                "boxShadowSecondary": "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;",
                "itemActiveBg": "rgba(129, 129, 129, 0.13)"
              },
              "Pagination": {
                "colorPrimary": "rgb(255, 59, 59)",
                "colorPrimaryBorder": "rgb(255, 192, 181)",
                "colorPrimaryHover": "rgb(190, 190, 190)"
              },
              "Button": {
                // "colorPrimary": "linear-gradient(to right, #fa709a 0%, #ffa840 100%);",
                // "colorPrimaryHover": "rgb(255, 99, 99)",
                // "colorPrimaryBorder": "rgb(255, 181, 181)",
                // "colorPrimaryActive": "rgb(217, 39, 39)"
              }
            }
          }
        }
      >
        <IntlProvider
          locale={getAppLocale().locale}
          messages={getAppLocale().messages}
          textComponent={Fragment}
        >
          <App />
        </IntlProvider>
      </ConfigProvider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
