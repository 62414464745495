export function getDateFormatString(data: Date | string, options?: Intl.DateTimeFormatOptions | undefined): string {
  if (!data) {
    return '';
  }

  const date: Date = typeof data === 'string' ? new Date(data) : data

  return new Intl.DateTimeFormat('th-TH', {
    dateStyle: 'medium',
    timeStyle: 'medium',
    ...options
  }).format(date);
}