import { Row, Col, Spin } from "antd";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType } from 'antd';
import { useIntl } from "react-intl";
import { Header } from "../Header";
import { UnorderedListOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { TableParams } from "interfaces/TableParams";
import { getShopListWithPaging } from "api/userShopService";
import "./Home.scss"
import { ShopCollapse } from "./ShopCollapse/ShopCollapse";

export const Homepage: React.FC = () => {
  const [data, setData] = useState<TableColumnsType[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 100,
      pageSizeOptions: [100]
    },
  });

  const intl = useIntl();
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getShopListWithPaging(tableParams)
      setData(response.data)
    } catch (error) {
      console.error('Failed to fetch transaction:', error);
      navigate(`/home`)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="home-page">
      <Row >
        <Col className="gutter-row" span={24}>
          <Header colorBg={'#1751ff'}
            title={intl.formatMessage({ id: `homePage.header` })}
            subtitle={intl.formatMessage({ id: `homePage.subtitle` })}
            icon={<UnorderedListOutlined />}
            rightButtonIcon={<ShoppingCartOutlined />}
            rightButtonText="servicePage.purchase"
            path={'/products'}
          />
        </Col>
      </Row>
      <Spin spinning={loading}>
        <ShopCollapse shops={data} />
      </Spin>
    </div>
  );
};
