// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-account-item-form {
  margin: 16px 0px;
}
.bank-account-item-form h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
}
.bank-account-item-form .ant-card {
  border-color: rgb(238, 156, 167) !important;
}
.bank-account-item-form .ant-card .ant-card-head {
  border-color: rgb(238, 156, 167) !important;
  color: rgb(238, 156, 167);
}
.bank-account-item-form .ant-card .ant-card-body .remark span {
  color: red;
  font-size: small;
}
.bank-account-item-form .ant-card .ant-card-body .ant-col {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/Branch/SettingTag/SystemSection/BankAccountForm/BankAccountItemFrom/BankAccountItemFrom.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AAEJ;AAAE;EACE,2CAAA;AAEJ;AAAI;EACE,2CAAA;EACA,yBAAA;AAEN;AAGQ;EACE,UAAA;EACA,gBAAA;AADV;AAKM;EACE,aAAA;AAHR","sourcesContent":[".bank-account-item-form {\n  margin: 16px 0px;\n  h3 {\n    margin-top: 0px;\n    margin-bottom: 10px;\n    font-size: 18px;\n  }\n  .ant-card{\n    border-color: rgb(238 156 167) !important;\n\n    .ant-card-head {\n      border-color: rgb(238 156 167) !important;\n      color: rgb(238 156 167);\n    }\n\n    .ant-card-body {\n      .remark {\n        span {\n          color: red;\n          font-size: small;\n        }\n      }\n\n      .ant-col {\n        padding: 16px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
