import axios from "axios"


export const api = axios.create({
  baseURL: process.env?.REACT_APP_API_BASE_URL ?? "/api",
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
  },
})
