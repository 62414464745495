import React, { useState } from 'react';
import { Modal, Input, Card, Col, Row, Divider, Button, Radio } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl";
import { formatNumberCurrency } from 'utils/number';
import { WalletOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

interface ModalProductInterface {
  modelTitle: string;
  isOpenModel: boolean;
  cancelModel: any;
  onOk: any;
  confirmLoading: boolean;
  placeholder: string;
  userWallet: number;
  topUpHandeler: any;
  creditPackage: any
}

export const PurchaseConfirmModal: React.FC<ModalProductInterface> = ({ modelTitle, isOpenModel, cancelModel, onOk, confirmLoading, placeholder, userWallet, topUpHandeler, creditPackage }) => {
  const [valiable, setValiable] = useState("");
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Modal
      title={modelTitle}
      open={isOpenModel}
      onOk={() => onOk(valiable)}
      confirmLoading={confirmLoading}
      onCancel={cancelModel}
      okButtonProps={userWallet === 0 && creditPackage.price !== 0 ? { style: { display: 'none' } } : undefined}
    >
      <Card >
        <div style={{ width: '100%', textAlign: 'center' }}>
          {intl.formatMessage({ id: `productPage.purchaseDetail` })}
        </div>
        <div>
          <Divider className='title-highlight'>{creditPackage.name}</Divider>
          <Row style={{ marginTop: '10px' }}>
            <Col span={12}>
              <div>{intl.formatMessage({ id: `productPage.maxCheck` })} <span >{formatNumberCurrency(creditPackage.creditAmount, 0, 0)} {intl.formatMessage({ id: `productPage.slip` })}  </span></div>
              <div>{intl.formatMessage({ id: `productPage.time` })} <span >{creditPackage.expirationDays} {intl.formatMessage({ id: `productPage.day` })}  </span></div>
            </Col>
          </Row>
          <hr />

          <Row style={{ marginTop: '10px' }}>
            <Col span={12}> {intl.formatMessage({ id: `productPage.totalAmount` })} </Col>
            <Col span={12} style={{ textAlign: 'right' }}>  {formatNumberCurrency(creditPackage.price, 2, 2)} {intl.formatMessage({ id: `common.baht` })} </Col>
          </Row>
          <Row>
            <Col span={12}> {intl.formatMessage({ id: `productPage.discount` })} </Col>
            <Col span={12} style={{ textAlign: 'right' }}>  0.00 {intl.formatMessage({ id: `common.baht` })} </Col>
          </Row>
          <Row>
            <Col span={12} className='title-highlight'> {intl.formatMessage({ id: `productPage.totalAmount` })} </Col>
            <Col span={12} className='title-highlight' style={{ textAlign: 'right' }}>  {formatNumberCurrency(creditPackage.price, 2, 2)} {intl.formatMessage({ id: `common.baht` })} </Col>
          </Row>
        </div>
      </Card>
      <Card style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Row>
          <Col span={12}>
            <Radio defaultChecked={true}></Radio> <WalletOutlined /> Your wallet <br />
            {intl.formatMessage({ id: `productPage.walletRemaining` })} <span className='title-highlight'>{formatNumberCurrency(userWallet, 2, 2)}</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="dashed" onClick={() => navigate("/wallet")} >{intl.formatMessage({ id: `productPage.topup` })}</Button>
          </Col>
        </Row>
        {userWallet === 0 && creditPackage.price !== 0 ? (
          <Row style={{paddingTop: '20px'}}><Col span={24} style={{ textAlign: 'center' }} className='title-highlight'> {userWallet === 0 ? intl.formatMessage({ id: `productPage.walletAlert` }) : ''} ! </Col></Row>
        ) : (<></>)}
      </Card>
      <span>{placeholder}</span>
      <Input disabled={userWallet === 0 && creditPackage?.price !== 0} placeholder={placeholder} prefix={<ShopOutlined />} value={valiable} onChange={(e) => setValiable(e.target.value)} />
    </Modal>
  );
}
