import { UserAccount } from "shared/dist"
import { useUserProfileSWR } from "./services"
import { PATHS } from "api/paths"
import { api } from "./base";

export const USER_ACCOUNT_UPDATE_URL = "/user-account/update/:id";
export const USER_ACCOUNT_CREATE_URL = "/user-account/create";
export const USER_ACCOUNT_CHANGE_PASSWORD_URL = "/user-account/changePassword/:id";

export interface IUseUserAccount {
  account: UserAccount
  isLoading: boolean
  updateUserAccount: (id: string, userAccount: Partial<UserAccount>) => Promise<void>
  createUserAccount: (userAccount: Partial<UserAccount>) => Promise<void>
}

export const useUserAccount = (): IUseUserAccount => {
  const { data: account, error, isLoading ,mutate } = useUserProfileSWR()
  if (error?.response?.status === 401 && !window.location.pathname.startsWith(PATHS.SIGNIN) && !window.location.pathname.startsWith(PATHS.SIGNUP)) {
    window.location.href = '/auth/sign-in'
  }

  async function createUserAccount(userAccount: Partial<UserAccount>) {
    const response = await api.post(USER_ACCOUNT_CREATE_URL, userAccount);
    mutate()
    return response.data;
  }

  async function updateUserAccount(id: string, userAccount: Partial<UserAccount>) {
    const response = await api.put(USER_ACCOUNT_UPDATE_URL.replace(":id", id), userAccount);
    mutate()
    return response.data;
  }

  return {
    createUserAccount,
    updateUserAccount,
    account: account as UserAccount,
    isLoading: isLoading,
  }
}
export async function changePassword(id: string, data: any) {
  const response = await api.put(USER_ACCOUNT_CHANGE_PASSWORD_URL.replace(":id", id), data);
  return response.data;
}
