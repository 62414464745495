import { ProductFrom } from "./ProductFrom";
import React from "react";

import "./Products.scss";

export const Products: React.FC = () => {
  return (
      <ProductFrom />
  );
};
