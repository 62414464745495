import React from "react";
import "./LoginForm.scss";
import { Form, Input, Button, Typography, Flex , Divider} from "antd";
import FeatherIcon from "feather-icons-react";
import logoCheckslip from "assets/img/logo.jpg";

const { Link } = Typography;

interface LoginFormProps {
  onSubmit(data: { username: string; password: string; remember: boolean }): void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    onSubmit(values);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
       layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <div className="header-login">
      {/* <img src={logoCheckslip} className="brand-logo-checkslip" /> */}
        <h2 className="title-highlight">เข้าสู่ระบบ</h2>
    </div>
      <Form.Item
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            message: "กรุณากรอกบัญชีผู้ใช้ของท่าน",
          },
        ]}
      >
        <Input size="large" prefix={<FeatherIcon icon="user" className="site-form-item-icon-custom" />} placeholder="บัญชีผู้ใช้" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input.Password
          size="large"
          prefix={<FeatherIcon icon="lock" className="site-form-item-icon-custom" />}
          type="password"
          placeholder="รหัสผ่าน"
        />
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="w-100 button-main">
          เข้าสู่ระบบ
        </Button>
        <Flex justify="space-between" style={{marginTop: "10px"}}>
          <Link className="text-login-form" href="#">
            ลืมรหัสผ่าน
          </Link>
        </Flex>
      </Form.Item>
      <Divider className="">หรือ</Divider>
      <div className="sub-title">
        ยังไม่มีบัญใช่ไหม ?
        <Link className="text-login-form" href="/auth/sign-up">
           สร้างบัญชีใหม่
        </Link>
      </div>
    </Form>
  );
};
