import React, { useEffect, useState } from "react";
import { Avatar, Grid, Col, Button, Row, Flex } from "antd";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

interface HeaderProps {
  title: string;
  subtitle: string;
  path?: any;
  icon?: any;
  colorBg?: string;
  rightButtonIcon?: any;
  rightButtonText?: string;
  rightButtonDisplay?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  path,
  icon,
  colorBg,
  rightButtonIcon,
  rightButtonText,
  rightButtonDisplay = true,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const [isMobileScreen, setMobileScreen] = useState(true);

  useEffect(() => {
    setMobileScreen(() => !breakpoints.sm);
  }, [breakpoints]);

  const fontSize = isMobileScreen ? "10px" : "16px";
  const iconSize = isMobileScreen ? 45 : 70;
  return (
    <Row>
      <Col span={24}>
        <Flex align="center" justify="space-between" className="boder-bottom-line">
          <div className="header-title-customs">
            <h3 className="title-highlight">{title}</h3>
            <div className="subtitle">{subtitle}</div>
          </div>
          <Button
            style={!rightButtonDisplay ? {display: "none"}: undefined}
            icon={rightButtonIcon ? rightButtonIcon : <ArrowLeftOutlined />}
            className={rightButtonIcon ? "button-main" : "button-main-line"}
            type="primary"
            size={isMobileScreen ? "small" : "large"}
            onClick={() => navigate(path ? path : -1)}
          >
            {rightButtonText ? intl.formatMessage({ id: rightButtonText }) : intl.formatMessage({ id: `common.back` })}
          </Button>
        </Flex>
      </Col>
    </Row>
  );
};
