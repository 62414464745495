import React, { useEffect } from "react";
import "./FlexResponseForm.scss";
import { Form, Button, Flex, Typography, Card, Grid, Radio } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { UserBranch } from "shared/dist";
import FeatherIcon from "feather-icons-react";

interface FlexResponseFormProps {
  onSubmit(data: any): void;
  userBranch: UserBranch
}

export const FlexResponseForm: React.FC<FlexResponseFormProps> = ({ onSubmit, userBranch }) => {
  const onFinish = (values: any) => {
    // console.log("Received values of form: ", values);
    onSubmit(values);
  };
  const intl = useIntl();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const fontSize = breakpoints.xs ? "12px" : "15px";

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ validSlip: userBranch.validSlip, duplicateSlip: userBranch.duplicateSlip, invalidSlip: userBranch.invalidSlip, invalidPayee: userBranch.invalidPayee, invalidQrCode: userBranch.invalidQrCode });
  }, [userBranch.validSlip, userBranch.duplicateSlip, userBranch.invalidSlip, userBranch.invalidPayee, userBranch.invalidQrCode])


  return (
    <div className="flex-response-form" >
      <Card title={<><FeatherIcon icon="message-circle" /> <IntlMessages id="settingPage.settingForSendTextTo" /></>}>
        <Form
          name="notify-form"
          onFinish={onFinish}
          form={form}
        >
          <div className="menu-list-setup">
            <h2>
              {intl.formatMessage({ id: `settingPage.slipIsCorrect` })}
            </h2>
            <Form.Item name="validSlip" className="mb-0">
              <Radio.Group defaultValue="LineBroadcast" buttonStyle="solid">
                <Radio.Button value="LineBroadcast">{userBranch.lineGroupType} Chat</Radio.Button>
                <Radio.Button value="LineNoti">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                <Radio.Button value="Both">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="menu-list-setup">
            <h2>
              {intl.formatMessage({ id: `settingPage.slipBeingUsed` })}
            </h2>
            <Form.Item name="duplicateSlip" className="mb-0">
              <Radio.Group defaultValue="LineBroadcast" buttonStyle="solid">
                <Radio.Button value="LineBroadcast">{userBranch.lineGroupType} Chat</Radio.Button>
                <Radio.Button value="LineNoti">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                <Radio.Button value="Both">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="menu-list-setup">
            <h2>
              {intl.formatMessage({ id: `settingPage.slipCanNotSeeOrExpiration` })}
              </h2>
            <Form.Item name="invalidSlip" className="mb-0">
              <Radio.Group defaultValue="LineBroadcast" buttonStyle="solid">
                <Radio.Button value="LineBroadcast">{userBranch.lineGroupType} Chat</Radio.Button>
                <Radio.Button value="LineNoti">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                <Radio.Button value="Both">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="menu-list-setup">
            <h2>
              {intl.formatMessage({ id: `settingPage.slipNotEnough` })}
             </h2>
            <Form.Item name="invalidPayee" className="mb-0">
              <Radio.Group defaultValue="LineBroadcast" buttonStyle="solid">
                <Radio.Button value="LineBroadcast">{userBranch.lineGroupType} Chat</Radio.Button>
                <Radio.Button value="LineNoti">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                <Radio.Button value="Both">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="menu-list-setup">
            <h2>
              {intl.formatMessage({ id: `settingPage.canNotQRFromPicture` })}
            </h2>
            <Form.Item name="invalidQrCode" className="mb-0">
              <Radio.Group defaultValue="LineBroadcast" buttonStyle="solid">
                <Radio.Button value="LineBroadcast">{userBranch.lineGroupType} Chat</Radio.Button>
                <Radio.Button value="LineNoti">{breakpoints.xs ? "Line Noti" : "Line Notify"}</Radio.Button>
                <Radio.Button value="Both">{intl.formatMessage({ id: `settingPage.both` })}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <Button size="large" className="button-main" type="primary" htmlType="submit">
              {intl.formatMessage({ id: `settingPage.saveData` })}
            </Button>
        </Form>
      </Card>
    </div>
  );
};
