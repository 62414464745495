import React from "react";
import { Card } from "antd";
import "./OverviewCard.scss";
import cx from 'classnames'

interface OverviewCardProps {
  title: string
  text: any
  icon: any
  borderLeftColor?: 'green' | 'yellow' | 'orange' | 'red'
}

export const OverviewCard: React.FC<OverviewCardProps> = ({
  title,
  text,
  icon,
  borderLeftColor = 'green'
}) => {
  return (
    <Card className={cx("overview-card", `border-left-${borderLeftColor}`)}>
      <div className="content">
        <div className="header-overview-card">
          <div className="icon">{icon}</div>
          <div className="title">{title}</div>
        </div>
       
        <div className="text title-highlight">
          {text}
          {/* <span>บาท</span> */}
          </div>
      </div>
    </Card>
  );
};
