import React, { useState } from "react";
import { Card, Button, message } from 'antd'
import { CreditPackage } from "interfaces/CreditPackage";
import FeatherIcon from "feather-icons-react";
import "./ProductsCarditems.scss";
import { createUserPacket } from "api/userPackageService";
import { useNavigate } from "react-router-dom";
import { formatNumberCurrency } from "utils/number";
import { useIntl } from "react-intl";
import { PurchaseConfirmModal } from "../Products/ProductConfirmModal";

export interface ProductsCardItemsProps {
  creditPackage: CreditPackage;
  onSelect: (id: number) => void;
  isDisableFreePackage: boolean;
  useWallet: number
}
export const ProductsCardItems: React.FC<ProductsCardItemsProps> = ({ creditPackage, onSelect, isDisableFreePackage, useWallet }) => {
  const navigate = useNavigate()

  const showModal = () => {
    setModalOpen(true);
  };
  const intl = useIntl();
  const [openModal, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async (shopeName: string) => {

    setConfirmLoading(true);
    try {
      await createUserPacket(shopeName, creditPackage).then(() => {
        setTimeout(async () => {
          setModalOpen(false);
          setConfirmLoading(false);
          navigate("/home")
        }, 2000);
      });
    } catch (error) {
      console.error('Failed to fetch Product Credit:', error);
      message.error(intl.formatMessage({ id: `message.somethingWasWrong` }))
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };


  return (
    <Card hoverable className="ProductsCarditems">
      {/* <!-- Card items --> */}
      <div className="card-product-body">
        <div className="title-header">
          <div className="description" > {creditPackage.description}</div>
          <h3 className="title-highlight">{creditPackage.name} </h3>
          <h2 className="title-highlight price">{formatNumberCurrency(creditPackage.price, 0, 0)} <span>{intl.formatMessage({ id: `common.baht` })} </span></h2>
        </div>
       
        <ul>
          <li>
            <div className="icon green">
              <FeatherIcon icon="check" />
            </div>
            <div>{intl.formatMessage({ id: `productPage.maxCheck` })} <span>{formatNumberCurrency(creditPackage.creditAmount, 0, 0)} {intl.formatMessage({ id: `productPage.slip` })}  </span></div>
          </li>
          <li>
            <div className="icon green">
              <FeatherIcon icon="check" />
            </div>
            <div>{intl.formatMessage({ id: `productPage.pricePerSlip` })}  <span>{formatNumberCurrency(creditPackage.expirationDays / creditPackage.creditAmount, 2, 3)} {intl.formatMessage({ id: `productPage.slip` })} </span> </div>
          </li>
          <li>
            <div className="icon green">
              <FeatherIcon icon="check" />
            </div>
            <div>{intl.formatMessage({ id: `productPage.maxConnection` })}  <span>{creditPackage.maxConnect} {intl.formatMessage({ id: `productPage.branch` })} </span></div>
          </li>
          <li>
            <div className="icon blue">
              <FeatherIcon icon="clock" />
            </div>
            <div>{intl.formatMessage({ id: `productPage.time` })}  <span>{creditPackage.expirationDays} {intl.formatMessage({ id: `productPage.day` })}  </span></div>
          </li>
        </ul>
        <Button size="large" disabled={creditPackage.price === 0 && isDisableFreePackage} className="w-100 button-main" type="primary" onClick={showModal} >
          {creditPackage.price === 0 && isDisableFreePackage ? intl.formatMessage({ id: `productPage.cantPurchase` }) : intl.formatMessage({ id: `productPage.purchase` })}
        </Button>
      </div>

      <PurchaseConfirmModal
        modelTitle={intl.formatMessage({ id: `productPage.confirmTitle` }) + creditPackage.name + " ?"}
        isOpenModel={openModal}
        cancelModel={handleCancel}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        placeholder={intl.formatMessage({ id: `productPage.fillShopName` })}
        userWallet={useWallet}
        topUpHandeler={undefined}
        creditPackage={creditPackage} />
    </Card>
  );
};