import { TableParams } from "interfaces/TableParams";
import { api } from "./base";

export const USER_WALLET = "/user-wallet";

export async function getUserWallet() {
  const response = await api.get(USER_WALLET)
  return response.data
}

export async function getUserWalletHistories(tableParams : TableParams) {
  const response = await api.post(USER_WALLET, {tableParams})
  return response.data
}