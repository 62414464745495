import React, { useEffect } from "react";
import "./BankAccountItemFrom.scss";
import { Form, Input, Button, Col, Flex, Typography, Row, Select, Skeleton, Spin } from "antd";
import { useIntl } from "react-intl";
import { DeleteFilled } from "@ant-design/icons";
import { BankAccount } from "shared/dist";

interface BankAccountItemFromProps {
  onSubmit(data: Partial<BankAccount>): void;
  onDelete(id: number): void;
  onClose(): void;
  index: number
  bankAcount?: BankAccount
  mode: string
  loading: boolean
}

export const BankAccountItemFrom: React.FC<BankAccountItemFromProps> = ({ mode, onSubmit, index, bankAcount, onClose, onDelete, loading }) => {
  const onFinish = (values: any) => {
    // console.log("Received values of form: ", values);
    onSubmit(values);
  };
  const intl = useIntl();
  const { Text } = Typography;
  const [bankForm] = Form.useForm();

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    // console.log(`bankAcount: `, bankAcount)
    bankForm.setFieldsValue({ bankCode: bankAcount?.bankCode, accountNumber: bankAcount?.accountNumber, firstNameEn: bankAcount?.firstNameEn, lastNameEn: bankAcount?.lastNameEn, firstNameTh: bankAcount?.firstNameTh, lastNameTh: bankAcount?.lastNameTh });
  }, [bankAcount?.bankCode, bankAcount?.accountNumber, bankAcount?.firstNameEn, bankAcount?.lastNameEn, bankAcount?.firstNameTh, bankAcount?.firstNameTh])

  if (!bankAcount) {
    return <Skeleton active />
  }

  return (
    <Spin spinning={loading}>
      <div className="bank-account-item-form" >
        <Flex
          vertical
          style={{
            marginLeft: "1rem",
            marginRight: "1rem",
            marginTop: "0.8rem",
            borderRadius: "5px",
          }}
        >
          <Flex justify="space-between" align="center" style={{ fontWeight: "600", marginTop: "0.5rem" }}>
            <h3 className="title-highlight">{intl.formatMessage({ id: `branchPage.atAccount` }, { number: index })}</h3>
            {mode !== 'create' && <Button onClick={() => onDelete(bankAcount.id as number)} icon={<DeleteFilled style={{ color: "red" }} />} />}
          </Flex>
          <Form name="bank-account-item-form" onFinish={onFinish} form={bankForm} initialValues={bankAcount} >
            <Row>
              <Col span={24}>
                <Text>{intl.formatMessage({ id: `settingPage.bankProvider` })}</Text>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  name="bankCode"
                >
                  <Select
                    showSearch
                    placeholder={intl.formatMessage({ id: `settingPage.bankProvider` })}
                    optionFilterProp="children"
                    filterOption={filterOption}
                    size="large"
                    options={[
                      {
                        label: 'SCB - ไทยพาณิชย์',
                        value: '014',
                      },
                      {
                        label: 'BBL - กรุงเทพ',
                        value: '002',
                      },
                      {
                        label: 'KBANK - กสิกรไทย',
                        value: '004',
                      },
                      {
                        label: 'KTB - กรุงไทย',
                        value: '006',
                      },
                      {
                        label: 'TTB - ทหารไทยธนชาต',
                        value: '011',
                      },
                      {
                        label: 'CIMBT - ซีไอเอ็มบีไทย',
                        value: '022',
                      },
                      {
                        label: 'GSB - ออมสิน',
                        value: '030',
                      },
                      {
                        label: 'TISCO - ทิสโก้',
                        value: '067',
                      },
                      {
                        label: 'KK - เกียรตินาคิน',
                        value: '069',
                      },
                      {
                        label: 'LHBANK - แลนด์ แอนด์ เฮ้าส์',
                        value: '073',
                      },
                    ]}
                  />
                </Form.Item>

                <Text>{intl.formatMessage({ id: `settingPage.accountNumber` })}</Text>
                <Form.Item style={{ marginBottom: 25 }} name="accountNumber" >
                  <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.accountNumber` })} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex vertical>
                  <Text>{intl.formatMessage({ id: `settingPage.thaiNameAccount` })}</Text>
                  <Form.Item style={{marginBottom: 10 }} name="firstNameTh" >
                    <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.thaiNameAccount` })} />
                  </Form.Item>
                  <Text>{intl.formatMessage({ id: `settingPage.thaiLastNameAccount` })}</Text>
                  <Form.Item style={{ marginBottom: 25 }} name="lastNameTh" >
                    <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.thaiLastNameAccount` })} />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <Flex vertical>
                  <Text>{intl.formatMessage({ id: `settingPage.engNameAccount` })}</Text>
                  <Form.Item style={{ marginBottom: 10 }} name="firstNameEn" >
                    <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.engNameAccount` })} />
                  </Form.Item>
                  <Text>{intl.formatMessage({ id: `settingPage.engLastNameAccount` })}</Text>
                  <Form.Item style={{ marginBottom: 25 }} name="lastNameEn" >
                    <Input size="large" placeholder={intl.formatMessage({ id: `settingPage.engLastNameAccount` })} />
                  </Form.Item>
                </Flex>
              </Col>

              <Col span={24}>
                <Flex justify="end" align="center" gap={12} >
                  <Button size="large" className="button-main-line" onClick={onClose}>{intl.formatMessage({ id: `common.cancel` })}</Button>
                  <Button size="large" className="button-main" htmlType="submit" type="primary" >{intl.formatMessage({ id: `settingPage.saveData` })}</Button>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Flex>
      </div >
    </Spin>
  );
};
