import React from "react";
import { Row, Col, ColProps } from "antd";
import "./BranchTab.scss";
import { BranchCard } from "./BranchCard";
import { CreateBranchCard } from "./CreateBranchCard";
import { UserBranch } from "shared/dist";

interface BranchTabProps {
  handelBranchCreate: (shopName: string) => Promise<void>;
  shopName: string;
  branchs: UserBranch[]
  handelBranchDelete: (branchId: number) => Promise<void>;
}

export const BranchTab: React.FC<BranchTabProps> = ({ shopName, branchs, handelBranchCreate, handelBranchDelete }) => {
  const colSmallStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xxl: { span: 8 },
  };
  
  return (
    <div className="branch-tab">
      <Row className="" gutter={[20, 20]} align="stretch">
        {branchs.map(branch =>
          <Col {...colSmallStyle}
            key={branch.id}>
            <BranchCard
              shopName={shopName}
              branchId={branch.id}
              branchName={branch.branchName}
              isMasterBranch={branch.isMasterBranch}
              allUsed={branch.totalRecord}
              totalAmount={branch.totalAmount}
              onDeleteBranch={handelBranchDelete}
            />
          </Col>
        )}
        <Col {...colSmallStyle} >
          <CreateBranchCard
            size={branchs.length}
            limit={10}
            handelBranchCreate={handelBranchCreate}
          />
        </Col>
      </Row>
    </div>
  );
};
