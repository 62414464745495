import { Button, Flex } from "antd";
import React, { useEffect, useState } from 'react';
import { GetProp, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType, TableProps } from 'antd';
import { UserAccount } from "shared/dist"
import { api } from 'api/instances'
import { PATHS } from "api/paths";
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const data: UserAccount[] = [];

export const UserManage: React.FC = () => {
  const [data, setData] = useState<UserAccount[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const navigate = useNavigate();
  const columns: TableColumnsType<UserAccount> = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Username', dataIndex: 'username', key: 'username' },
    { title: 'ชื่อ', dataIndex: 'firstName', key: 'firstName' , render: (text: any, record: UserAccount) =>  `${record.firstName} ${record.lastName}` },
    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
    { title: 'Contact', dataIndex: 'contact', key: 'contact' },
    { title: 'Created At', dataIndex: 'createdAt', key: 'createdAt', render: (date: Date) => new Date(date).toLocaleString() },
    {
      title: 'Action',
      key: 'action',
      sorter: true,
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`form/${record.id}`)}>
          Edit
        </Button>
      ),
    },
  ];

  const requestObj = {
    name: '',
    current: 1,
    pageSize: 20
  };


  const fetchData = () => {
    setLoading(true);
    try {
      api.post(PATHS.USER_ACCOUNT_PROFILE, { requestObj })
        .then(res => {
          // console.log(res)
          setData(res.data.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.meta.total,
              showSizeChanger: true,
              pageSizeOptions: [20, 50, 100]
            },
          });
        })
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);


  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  return (
    <>
      <div><h1>Credit Package List</h1></div>
      <Flex gap="small" >
        <Button href="/admin/user-manage/form" type="primary">เพิ่มยูสเซอร์</Button>
      </Flex>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};
