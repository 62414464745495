import React, { useEffect, useState } from "react";
import { Card, Table, Typography } from "antd";
import type { GetProp, TableColumnsType, TablePaginationConfig, TableProps } from 'antd';
import { useIntl } from "react-intl";
import "./CustomerTab.scss";
import IntlMessages from "components/IntlMessages";
import { FilterForm } from "./FilterForm";
import { getCustomerListWithPaging } from "api/userCustomerService";
import moment, { Moment } from 'moment'
import { UserBranch } from "shared/dist";
import { getDateFormatString } from "utils/date";
const { Text } = Typography;
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}
interface SystemSectionProps {
  branch: UserBranch
}
export const CustomerTab: React.FC<SystemSectionProps> = ({ branch }) => {
  const intl = useIntl();
  const columns: TableColumnsType<any> = [
    {
      title: <IntlMessages id="branchPage.nickname" />,
      dataIndex: 'name',
    },
    {
      title: <IntlMessages id="branchPage.amountOfMoney" />,
      dataIndex: 'totalAmount',
      render: (totalAmount: number) => <> <Text strong type="success"> {totalAmount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text></>
    },
    {
      title: <IntlMessages id="branchPage.firstRecordedAt" />,
      dataIndex: 'createdAt',
      render: (date: Date) => getDateFormatString(date)
    },
  ];

  const [data, setData] = useState<TableColumnsType[]>();
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate()
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [filter, setFilter] = useState({ name: '', timeToSendSlip: [] });
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([null, null])
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  useEffect(() => {
    fetchData();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getCustomerListWithPaging(
        tableParams,
        branch?.id,
        filter.name,
        dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null,
        dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null
      )
      setData(response.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.meta.total,
          showSizeChanger: true,
          pageSizeOptions: [20, 50, 100]
        },
      });
    } catch (error) {
      console.error('Failed to fetch customer:', error);
      // navigate(`/home`)
    } finally {
      setLoading(false);
    }
  };
  // const handleTableChange = (newPagination) => {
  //   setPagination(newPagination);
  // };
  const onHandelFilterCustomerSubmit = (filter: any) => {
    console.log(`filter: `, filter)
    setFilter(filter)
    setDateRange(filter.timeToSendSlip)
    setPagination({ ...pagination, current: 1 }); // Reset to first page on search
    fetchData();
  }
  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  return (
    <Card className="customer-tab">
      <FilterForm onSubmit={onHandelFilterCustomerSubmit} />
      <Table columns={columns}
        onChange={handleTableChange}
        loading={loading}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={tableParams.pagination}  />
    </Card>
  );
};
