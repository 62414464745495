import { Col, Flex, Row, Typography, Avatar, RowProps, Grid, Steps, StepProps } from "antd";
import React, { useState } from "react";
import {
  UserOutlined,
  HomeOutlined,
  CheckOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { OverviewPage } from "./Overview"
import { UserShop, FlexboxType, NotificationType, ShopStatus } from "shared/dist"
import { useUserAccount } from "api/useUserAccount";
import Homepage from "./HomePage";
import { useIntl } from "react-intl";
import IntlMessages from "components/IntlMessages";
export const SettingFrom: React.FC = () => {
  const { account } = useUserAccount()
  const intl = useIntl();
  const [current, setCurrent] = useState(0);
  const [shopSetting, setShopSetting] = useState<UserShop>({
    userId: account.id,
    shopName: "ทดลองระบบฟรี7วัน",
    shopStatus: ShopStatus.active,
    shopIsActive: true,
    isFreePackage: true,
    userPackageId: 0,
    isAutoRenewed: false,
    isAutoTopUp: false,
    flexboxType: FlexboxType.card,
    userBranchs: [
    //   {
    //   userId: 0,
    //   shopId: 0,
    //   branchName: "",
    //   lineGroupType: "",
    //   lineChannelId: "",
    //   lineChannelSecret: "",
    //   webHookUrl: "",
    //   lineNotifyTokeyn: "",
    //   isActive: false,
    //   remark: "",
    // }
    ],
    bankAccounts: [
      {
        bankCode: "",
        accountNumber: "",
        firstNameTh: "",
        lastNameTh: "",
        firstNameEn: "",
        lastNameEn: "",
      }
    ]
  });

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const fontLevel = breakpoints.xs ? 5 : 3;
  const rowStyle: RowProps = { gutter: { xs: 8, sm: 16, md: 24, lg: 32 } };
 

  const steps: (StepProps & { content: JSX.Element })[] = [
    {
      title: <IntlMessages id="settingPage.mainPage" />,
      content: <Homepage shopSetting={shopSetting} setShopSetting={setShopSetting} />,
      status: "process",
      icon: <HomeOutlined onClick={() => setCurrent(0)} />,
    },
    {
      title: <IntlMessages id="settingPage.tryFree" />,
      content: <OverviewPage />,
      icon: <CheckOutlined onClick={() => setCurrent(1)} />,
    },
    {
      title: <IntlMessages id="settingPage.settings" />,
      content: <>Last-content</>,
      icon: <SettingOutlined onClick={() => setCurrent(2)} />,
    },
    {
      title: <IntlMessages id="settingPage.history" />,
      content: <>Last-content</>,
      icon: <UnorderedListOutlined onClick={() => setCurrent(3)} />,
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    icon: item.icon,
    status: item.status,
    title: item.title,
  }));

  return (
    <>
      <Row style={{ padding: "10", paddingLeft: "1rem", paddingRight: "1rem", marginBottom:"1rem" }} {...rowStyle}>
        <Col span={24}>
          <Flex justify="space-between" align="center" >
            <Typography.Title level={fontLevel}>{intl.formatMessage({ id: `settingPage.tryFree` })} </Typography.Title>
            <Avatar size={40} icon={<UserOutlined />} />
          </Flex>
        </Col>
      </Row>

      <Row style={{ padding: "10", paddingLeft: "1rem", paddingRight: "1rem"}} {...rowStyle}>
        <Col span={24} style={{marginBottom:"1.5rem"}}>
          <Flex justify="center" align="center" vertical={breakpoints.xs ? true : false}>
            <Steps responsive={true} direction={breakpoints.xs ? "vertical" : "horizontal"} size="small" current={current} items={items} />
          </Flex>
        </Col>
        {steps[current].content}
      </Row>
    </>
  );
};
