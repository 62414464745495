import { RegisterFrom } from "./RegisterFrom";
import React, { useState } from "react";
import { Flex } from "antd";
import "./Register.scss";
import imgSignup from "assets/img/img-signup.jpg";
import { UserAccount } from "shared/dist";
import { useUserAccount } from "api/useUserAccount";
import { useNotification } from "components/Notification";
import IntlMessages from "components/IntlMessages";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export const Register: React.FC = () => {
  const { createUserAccount } = useUserAccount();
  const { notification, contextNotification } = useNotification();
  const navigate = useNavigate();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handelCreateUaerAccount = async (userAccount: Partial<UserAccount>) => {
    try {
      setIsLoading(true);
      await createUserAccount(userAccount);
      notification.success({
        message: <IntlMessages id="success" />,
        description: intl.formatMessage({ id: `message.saveEntitySuccess` }, { entity: intl.formatMessage({ id: `registerPage.entity` }) }),
        placement: 'topRight',
      });
      setTimeout(() => {
        navigate('/auth/sign-in')
      }, 1000);
      
    } catch (err) {
      notification.error({
        message: <IntlMessages id="failed" />,
        description: intl.formatMessage({ id: `message.somethingWasWrong` }),
        placement: 'topRight',
      });
    } finally {
      setIsLoading(false);
    }

  }

  return (
    <div className="register-page">
      {contextNotification}
      <Flex className="h-100">
        <div className="content-register">
          <RegisterFrom onSubmit={handelCreateUaerAccount} isLoading={isLoading} />
        </div>
        <div className="content-carousel">
          <img src={imgSignup} />
        </div>
      </Flex>
    </div>
  );
};
