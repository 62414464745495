import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tabs, Spin, Grid, ColProps, Skeleton } from "antd";
import { SettingSection } from "./SettingSection"
import { ShopOverviewTab } from "./ShopOverviewTab"
import { BranchTab } from "./BranchTab"
import { BillingHistoryTab } from "./BillingHistoryTab"
import { useParams, useNavigate } from 'react-router-dom';
import "./Shop.scss"
import { getUserShopDetail, updateShopName } from "api/userShopService";
import type { TabsProps } from 'antd';
import IntlMessages from "components/IntlMessages";
import { HeaderNavigate } from "components/HeaderNavigate";
import { UserShop } from "shared/dist";
import { createUserBranch, updateUserBranch } from "api/userBranchService";
import FeatherIcon from "feather-icons-react";

export const Shop: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [shop, setShop] = useState<UserShop | undefined>(undefined);
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const tabSize = breakpoints.xs ? "small" : "large";
  const tabType = breakpoints.xs ? undefined : "line";
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  
  const handleTabChange = (key: string) => {
    console.log('key:', key)
    if(key === "billingHistory"){
      ref.current?.fetchData();
    }
  };

  useEffect(() => {
    if (id) {
      fetchtShopPackage(id);
    }
  }, [id]);

  const fetchtShopPackage = async (userShopId: string) => {
    setLoading(true);
    try {
      const response = await getUserShopDetail(userShopId)
      console.log(response)
      setShop(response)
    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
      navigate(`/home`)
    } finally {
      setLoading(false);
    }
  };

  if (!id || !shop) {
    return <Skeleton active />;
  }

  const handelBranchCreate = async (branchName: string) => {
    try {
      const response = await createUserBranch(branchName, shop.id as number);
      console.log(response);
      await fetchtShopPackage(id as string);
    } catch (error) {
      console.error('Failed to fetch createUserBranch :', error);
    } finally {

    }
  }

  const handelBranchDelete = async (branchId: number) => {
    try {
      await updateUserBranch(branchId, { isDeleted: true });
      await fetchtShopPackage(id as string);
    } catch (error) {
      console.error('Failed to fetch createUserBranch :', error);
    }
  }


  const items: TabsProps['items'] = [
    {
      key: 'shopOverview',
      label: breakpoints.md ? <><span><IntlMessages id="shopPage.shopOverview" /></span></> : <span><IntlMessages id="shopPage.shopOverview" /></span>,
      children: <ShopOverviewTab />,
    },
    {
      key: 'branchs',
      label: breakpoints.md ? <><span><IntlMessages id="shopPage.branchs" /></span></> : <span><IntlMessages id="shopPage.branchs" /></span>,
      children: <BranchTab handelBranchCreate={handelBranchCreate} handelBranchDelete={handelBranchDelete} branchs={shop.userBranchs} shopName={shop.shopName} />,
    },
    {
      key: 'billingHistory',
      label: breakpoints.md ? <><span><IntlMessages id="shopPage.billingHistory" /></span></> : <span><IntlMessages id="shopPage.billingHistory" /></span>,
      children: <BillingHistoryTab ref={ref} />,
    },
  ];

  const colStyle: ColProps = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xxl: { span: 24 },
  };

  const handelShopChange = async (data: Partial<UserShop>) => {
    setLoading(true);
    try {
      if (data.isAutoRenewed === undefined) {
        data.isAutoRenewed = false;
      }
      if (data.isAutoTopUp === undefined) {
        data.isAutoTopUp = false;
      }
      await updateShopName(id as string, data);

      await fetchtShopPackage(id);

    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ShopSection">
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <HeaderNavigate
              shopName={shop.shopName}
              shopId={shop.id}
              branchs={
                shop?.userBranchs?.map(ub => ({
                  id: ub.id as number,
                  name: ub.branchName,
                  isMasterBranch: ub.isMasterBranch
                }))
              }
            />
          </Col>
        </Row>
        <Row>
          {/* <Col {...colStyle} >
            <DetailSection shop={shop} onShopChange={(data) => handelShopChange(data)} />
          </Col> */}
          <Col {...colStyle} >
            <SettingSection shop={shop} onSubmit={(data) => handelShopChange(data)} onShopChange={(data) => handelShopChange(data)} />
          </Col>
          <Col span={24}>
            <Tabs
              className="tabsSection"
              defaultActiveKey="shopOverview"
              onChange={handleTabChange}
              type={tabType}
              size={tabSize}
              items={items}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Tabs
              className="tabsSection"
              defaultActiveKey="shopOverview"
              type={tabType}
              size={tabSize}
              items={items}
            />
          </Col>
        </Row> */}
      </Spin>
    </div>
  );
};