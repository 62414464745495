import React from "react";
import { Row, Col, Progress, ColProps } from "antd";
import { useIntl } from "react-intl";
import "./ShopOverviewTab.scss";
import { OverviewCard } from "components/OverviewCard";
import FeatherIcon from "feather-icons-react";

const colMediumStyle: ColProps = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xxl: { span: 12 },
};

// const colSmallStyle: ColProps = {
//   xs: { span: 24 },
//   sm: { span: 24 },
//   md: { span: 8 },
//   lg: { span: 8 },
//   xxl: { span: 8 },
// };

export const ShopOverviewTab: React.FC = () => {
  const intl = useIntl();
  return (
    <div className="shop-overview-tab">
      <div className="shop-overview-container">
        <Row gutter={[20, 20]}>
          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.totalAmount` })}
              text="0"
              icon={<FeatherIcon icon="pie-chart" />}
              borderLeftColor="green"
            />
          </Col>
          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.amountLowerSpecified` })}
              text="0"
              icon={<FeatherIcon icon="trending-down" />}
              borderLeftColor="yellow"
            />
          </Col>
          {/* <Col {...colSmallStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.fifteenMinutesLate` })}
              text="0"
              icon={<FeatherIcon icon="clock" />}
              borderLeftColor="orange"
            />
          </Col> */}

          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.totalSlips` })}
              text="0"
              icon={<FeatherIcon icon="layers" />}
              borderLeftColor="green"
            />
          </Col>
          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.slipLowerLimitAmount` })}
              text="0"
              icon={<FeatherIcon icon="chevrons-down" />}
              borderLeftColor="yellow"
            />
          </Col>
          {/* <Col {...colSmallStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.slipFifteenMinutesLate` })}
              text="0"
              icon={<FeatherIcon icon="clock" />}
              borderLeftColor="orange"
            />
          </Col> */}

          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.totalIncorrectNameOrAccount` })}
              text="0"
              icon={<FeatherIcon icon="user-x" />}
              borderLeftColor="red"
            />
          </Col>
          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.amountIncorrectNameOrAccount` })}
              text="0"
              icon={<FeatherIcon icon="file-minus" />}
              borderLeftColor="red"
            />
          </Col>

          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.expiredOrNotFoundSlips` })}
              text="0"
              icon={<FeatherIcon icon="slash" />}
              borderLeftColor="red"
            />
          </Col>
          <Col {...colMediumStyle} >
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.usage` }, { usage: 15, limit: 150 })}
              text={<Progress percent={15} showInfo={false} />}
              icon={<FeatherIcon icon="trending-up" />}
              borderLeftColor="red"
            />
          </Col>

          <Col span={24}>
            <OverviewCard
              title={intl.formatMessage({ id: `shopPage.totalCustomers` })}
              text="0"
              icon={<FeatherIcon icon="users" />}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
