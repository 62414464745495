import { Button, Col, Divider, Input, Row, Spin, message, Space, Tabs, Tooltip, Typography, notification, Tag, Table } from "antd";
import { useEffect, useState } from "react";
import { Header } from "../Header";
import { useIntl } from "react-intl";
import { UnorderedListOutlined, CopyOutlined, SyncOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { getUserWallet, getUserWalletHistories } from "api/userWalletService";
import IntlMessages from "components/IntlMessages";
import copy from "copy-to-clipboard"
import "./Wallet.scss";
import type { TableColumnsType, TableProps } from 'antd';
import { TableParams } from "interfaces/TableParams";
import FeatherIcon from "feather-icons-react";

const { Text } = Typography;

export const Wallet: React.FC = () => {
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const [uuidWallet, setUUIDWallet] = useState('')
  const [data, setData] = useState<any[]>();
  const navigate = useNavigate()
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100]
    },
  });

  useEffect(() => {
    fecthData();
  }, []);

  const fecthData = async () => {
    setLoading(true);
    try {
      const userWalletResponse = await getUserWallet()
      setUUIDWallet(userWalletResponse.uuid)
    } catch (error) {
      console.error('Failed to fetch creditPackage:', error);
    } finally {
      setLoading(false);
    }
  };

  const [api, contextHolder] = notification.useNotification();

  const handelCopyReference = () => {
    copy(`topup:${uuidWallet}`)
    message.success(intl.formatMessage({ id: `message.copyEntitySuccess` }, { entity: `topup:${uuidWallet}` }))
  }
  const copyToClipboard = async (uuid: string) => {
    await navigator.clipboard.writeText(uuid);
    api.success({
      message: `Copy to clipboard`,
      description: uuid,
      placement: 'topRight'
    });
  };
  const columns: TableColumnsType<any> = [
    {
      title: "ID",
      dataIndex: 'refId',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            copyToClipboard(record.refId);
          },
        };
      },
      render: (uuid: string) => <Tooltip placement="top" title={"คลิกเพื่อคัดลอก " + uuid}> <Text style={{ maxWidth: '100px' }} ellipsis={true}>{uuid}</Text> </Tooltip>
    },
    {
      title: <IntlMessages id="branchPage.verifySlipAt" />,
      dataIndex: 'createdAt',
      render: (date: Date) => new Date(date).toLocaleString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    {
      title: <IntlMessages id="branchPage.amountOfMoney" />,
      dataIndex: 'amount',
      render: (amount: number, record: any) => <> <Text strong type={record.responseStatus === 'success' ? 'success' : 'secondary'}>{record.responseStatus === 'success' ? '+' : ''} {amount?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text></>
      // sorter: true,
    },
    {
      title: <IntlMessages id="branchPage.status" />,
      dataIndex: 'status',
      render: (responseStatus: string, props: any) => {
        if (responseStatus && responseStatus === 'processing') {
          return (<Tag color="yellow" bordered={false}>  <SyncOutlined spin /><span style={{paddingLeft:'10px'}} > <IntlMessages id="transactionPage.processing" /></span> </Tag>)
        } else if (responseStatus && responseStatus === 'success') {
          return (<Tag color="green" bordered={false}>  <FeatherIcon icon="check-circle" /> <span style={{paddingLeft:'10px'}} ><IntlMessages id="transactionPage.success" /></span></Tag>)
        } else {
          return <Tag color="red" bordered={false}> <FeatherIcon icon="alert-circle"  /><span style={{paddingLeft:'10px'}} > {props?.message ? props?.message : ''}</span></Tag>
        }
      },
    },
  ];

  useEffect(() => {
    fetchTrasactionList();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);

  const fetchTrasactionList = async () => {
    setLoading(true);
    try {
      const response = await getUserWalletHistories(
        tableParams,
      )
      setData(response.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.meta.total,
          showSizeChanger: true,
          pageSizeOptions: [20, 50, 100]
        },
      });
    } catch (error) {
      console.error('Failed to fetch transaction:', error);
      navigate(`/home`)
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };


  return (

    <div className="wallet">
      <Spin spinning={loading}>
        <Row>
          <Col className="gutter-row" span={24}>
            <Header colorBg={'#1751ff'}
              rightButtonDisplay={false}
              title={intl.formatMessage({ id: `walletPage.pageTitle` })}
              subtitle={intl.formatMessage({ id: `walletPage.pageDescription` })}
              icon={<UnorderedListOutlined />}
              path={'/products'}
            />
          </Col>
        </Row>
        <Row style={{ textAlign: 'center', marginTop: '20px' }}>
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="วิธีการเติมเงิน" key="1">
                <Row style={{ textAlign: 'center' }}>
                  <Col span={24} >
                    1. กรุณาคลิกที่ลิงค์หรือแสกน QR CODE เพื่อเพิ่มเพื่อนสำหรับเติมเงินเข้าระบบ
                  </Col>
                  <Col span={24} style={{ paddingTop: '10px' }} >
                    <Link style={{ color: 'green' }} to={'https://line.me/R/ti/p/@507hgsss'} target="_blank"> https://line.me/ti/p/@138fqars</Link>
                  </Col>
                  <Col span={24} style={{ paddingTop: '10px' }} >
                    <img src="https://qr-official.line.me/gs/M_507hgsss_GW.png?oat_content=url" alt="xx" />
                  </Col>
                </Row>
                <Divider></Divider>
                <Row style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Col span={24}>
                    <span>2. กดปุ่ม คัดลอก แล้วนำข้อความไปส่งในไลน์ที่เพิ่มเพื่อนไว้ด้านบน</span>
                  </Col>

                  <Col span={24}>
                    <Space.Compact style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Input size="large" style={{ maxWidth: '400px' }} value={`topup:${uuidWallet}`} disabled />
                      <Button size="large" className="button-main" type="primary" onClick={handelCopyReference}><CopyOutlined /><IntlMessages id="common.copy" /> </Button>
                    </Space.Compact>
                  </Col>
                </Row>
                <Divider></Divider>
                <Row style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Col span={24}>
                    <span>3. โอนเงินที่ต้องการเติมเงินและส่งสลิปไปยังไลน์เดิมเพื่อรอยืนยัน เมื่อระบบเช็คสลิปสำเร็จจะทำการเติมเงินให้คุณอัติโนมัติ</span>
                  </Col>

                </Row>
              </TabPane>
              <TabPane tab="ประวัติการเติมเงิน" key="2">
                <Table
                  dataSource={data}
                  columns={columns}
                  onChange={handleTableChange}
                  rowKey={(record) => record.uuid}
                  loading={loading}
                  pagination={tableParams.pagination}
                  scroll={{ x: 'calc(700px + 50%)' }}
                />

              </TabPane>
            </Tabs>
          </Col>
        </Row>
        {contextHolder}

      </Spin>
    </div>
  )
}